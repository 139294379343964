import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['password', 'button'];

  connect() {
    this.buttonTarget.addEventListener('click', () => this.togglePeekPassword());
  }

  togglePeekPassword() {
    if (this.passwordTarget.type === 'password') {
      this.buttonTarget.querySelector('i').classList.remove('fa-eye-slash');
      this.buttonTarget.querySelector('i').classList.add('fa-eye');
      this.passwordTarget.type = 'text';
    } else {
      this.passwordTarget.type = 'password';
      this.buttonTarget.querySelector('i').classList.add('fa-eye-slash');
      this.buttonTarget.querySelector('i').classList.remove('fa-eye');
    }
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['paletteSelection', 'colorSelection'];

  colors = {
    primary: '',
    secondary: '',
    tertiary: '',
    primaryAccent: '',
    secondaryAccent: '',
    tertiaryAccent: '',
  };

  stylesheet = null;
  observer = null;

  connect() {
    let element = this.element;

    this.stylesheet = document.createElement('style');
    this.stylesheet.type = 'text/css';
    element.insertAdjacentElement('afterend', this.stylesheet);
    this.updateStylesheet();
    this.boundEventListener = this.eventListener.bind(this); // Create a version of the eventListener function, with the 'this' context bound to the Stimulus controller, even when passed to an event handler.

    const mutationCallback = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === 'childList') {
          // When new paletteSelection targets are added, add the event listner to them.
          if (mutation.target instanceof HTMLSelectElement && mutation.target.dataset['target'] && new RegExp(/(^|\s)[\w-]*theme-builder\.paletteSelection($|\s)/g).test(mutation.target.dataset['target'])) {
            mutation.target.removeEventListener('choice', this.boundEventListener); // Remove any previous listener if present
            mutation.target.addEventListener('choice', this.boundEventListener); // Bind event listener
          }
        }
      }
    };
    const mutationObserverConfig = {attributes: false, childList: true, subtree: true};
    this.observer = new MutationObserver(mutationCallback);
    this.observer.observe(this.element, mutationObserverConfig);

    if (this.hasPaletteSelectionTarget) {
      this.paletteSelectionTarget.addEventListener('choice', this.boundEventListener);
    }
  }

  eventListener(event) {
    let json = JSON.parse(event.detail.choice.customProperties);

    json.forEach((e) => {
      this.colors[e.name] = e.color;
    });
    this.updateStylesheet();
  }

  disconnect() {
    this.stylesheet.remove();
    this.observer.disconnect();
    delete this.observer;
  }

  updateStylesheet() {
    this.stylesheet.innerText = '';
    let newStyles = '';
    for (let key in this.colors) {
      if (this.colors.hasOwnProperty(key)) {
        newStyles += `.${key}.palette-colour-circle { background-color: ${this.colors[key]};}\n`;
      }
    }
    this.stylesheet.innerText = newStyles;
  }
}
import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import debounce from 'lodash.debounce';

export default class extends Controller {

  static targets = [
    'sidebar',
    'contentBlock',
  ];
  debouncedSaveChanges = debounce(this.saveChanges, 800).bind(this);

  connect() {
    this.recalculateDisplayOrders();
  }

  moveUp(event) {
    let contentBlock = event.target.closest('.widget');
    let prev = contentBlock.previousElementSibling;
    if (prev == null || !prev.classList.contains('widget')) {
      return;
    }
    contentBlock.remove();
    prev.insertAdjacentElement('beforebegin', contentBlock);
    this.recalculateDisplayOrders();
  }

  moveDown(event) {
    let contentBlock = event.target.closest('.widget');
    let next = contentBlock.nextElementSibling;
    if (next == null || !next.classList.contains('widget')) {
      return;
    }
    contentBlock.remove();
    next.insertAdjacentElement('afterend', contentBlock);
    this.recalculateDisplayOrders();
  }

  recalculateDisplayOrders() {
    this.contentBlockTargets.forEach((el, index) => {
      el.querySelector('input[name*="[display_order]"]').value = index;
    });
  }

  saveChanges() {
    Rails.fire(this.element, 'submit');
  }

}

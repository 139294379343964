import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const config = { attributes: true };
    const tabs = Array.from(
      this.element.querySelectorAll(
        ':scope > [data-tabs-headers] > [data-tabs-header]'
      )
    )
    const contents = Array.from(this.element.querySelectorAll(':scope > [data-tabs-content]'))

    this.observers = tabs.map((tab, index) => {
      const isSelected = () => tab.classList.contains('selected')

      const observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName == "class" && isSelected()) {
            contents.forEach((content, i) => {
              content.querySelectorAll("input").forEach(input => {
                if (i == this.element.dataset.index) {
                  input.removeAttribute("disabled")
                } else {
                  input.setAttribute("disabled", true)
                }
              })
            })
          }
        }
      });

      observer.observe(tab, config);

      return observer;
    });
  }

  disconnect() {
    this.observers.forEach((observer) => observer.disconnect());
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  boundHandler = this.handler.bind(this);

  connect() {
    this.element.addEventListener('submit', this.boundHandler);
  }

  async handler(e) {
    e.preventDefault();

    const form = e.target;

    let response = await fetch(form.action, {
      method: form.method,
      body: new FormData(form),
    });

    if (!response.ok) {
      // TODO: Error handling?
    }

    form.innerHTML = await response.text();
  }

}
import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['domain', 'subdomain', 'checking', 'available', 'unavailable', 'invalid', 'publish'];

  connect(){
    this.check()
  }

  check(e) {
    let domain = this.domainTarget.value;
    let subdomain = this.subdomainTarget.value.trim();

    this.showState(this.checkingTarget, false);

    if (this.validSubdomain(subdomain)) {
      let url = new URL(this.element.dataset.checkEndpoint);
      url.searchParams.append('domain', domain);
      url.searchParams.append('subdomain', subdomain);

      // Add params
      fetch(url.toString())
      .then(response => response.json())
      .then(data => {
        if (data.available) {
          this.showState(this.availableTarget, true);
        } else {
          this.showState(this.unavailableTarget, false);
        }
      });
    } else {
      this.showState(this.invalidTarget, false);
    }
  }

  validSubdomain(subdomain) {
    let r = new RegExp(/^[a-z0-9]+([\-]?[a-z0-9]+)*$/, 'i');

    if (subdomain.length < 8 || subdomain.length > 63) {
      return false;
    }

    if (!subdomain.match(r)) {
      console.log(subdomain, "did not match")
      return false;
    }

    return true;
  }

  hideStates() {
    this.checkingTarget.classList.add('hide');
    this.availableTarget.classList.add('hide');
    this.unavailableTarget.classList.add('hide');
    this.invalidTarget.classList.add('hide');
  }

  showState(state, allow_publish) {
    this.hideStates();
    state.classList.remove('hide');
    this.publishTargets.forEach(target => target.disabled = !allow_publish)
  }

  changeDomain(e){
    e.preventDefault()

    Swal.fire({
      title: '',
      html: '<p>Changing the address of your website will mean that any links you have shared with friends and family will no longer work.  Are you sure you want to proceed?</p>',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'button',
        cancelButton: 'button outlined red',
      }
    }).then(result => {
      if(result.isConfirmed){
        document.querySelector('.change-domain').classList.remove('hide')
        e.target.classList.add('hide')
      }
    });
  }
}

import { Controller } from 'stimulus';
import Picker from 'vanilla-picker';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = [ 'input', 'field', 'preview'];

  connect() {
    const defaultColor = this.fieldTarget.value || this.inputTarget.dataset.default
    this.previewTarget.style.backgroundColor = this.fieldTarget.value;

    this.picker = new Picker({ parent: this.inputTarget, alpha: false, color: defaultColor, popup: 'bottom'});

    this.picker.onChange = (color) => this.updateColor(color);
    this.picker.onDone = () => Rails.fire(this.element.closest('form'), 'submit');
    this.picker.onClose = () => Rails.fire(this.element.closest('form'), 'submit');
  }

  clickColorPicker(e) {
    e.preventDefault();
  }

  clickColorBox(e) {
    e.preventDefault();
    this.inputTarget.click();
  }

  updateColor(color) {
    const colorLength = color.hex.length;

    // # + 123456 + aa
    if (colorLength != 9) {
      throw new Error(`Expected the color hex length to be 9, but it was ${colorLength}`)
    }

    // # + 123456
    const colorWithoutAlpha = color.hex.slice(0, 7);
    this.fieldTarget.value = colorWithoutAlpha;
    this.previewTarget.style.backgroundColor = colorWithoutAlpha
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    window.addEventListener("beforeunload", this.areYouSure)
  }

  disconnect() {
    window.removeEventListener("beforeunload", this.areYouSure)
  }

  areYouSure(e) {
    e.preventDefault()
    return ''
  }
}

import { Controller } from "stimulus";
import Picker from "vanilla-picker";
import { emitter } from "../../lib/eventBus";

// this controller sits on a color picker and sends a global message
// when a color is chosen. this message is picked up by all remote_color_picker_receivers
// on the page, and if the names match, the input associated with the remote_color_picker_receiver
// is updated.
//
// the reason for this indirection is that color pickers can be anywhere in a page for usability
// reasons. the main example is that you want a font color picker underneath the font, whereas
// the actual form input should be in the in the palettes form with all the other colors.
export default class extends Controller {
  static targets = ["preview"]
  connect() {
    this.picker = new Picker({
      parent: this.element,
      alpha: false,
      color: this.element.dataset.color,
      popup: "right"
    })

    this.previewTarget.style.backgroundColor = this.element.dataset.color

    this.updateColor = this.updateColor.bind(this)
    this.broadCastChoice = this.broadCastChoice.bind(this)
    this.picker.onChange = this.updateColor
    this.picker.onClose = this.broadCastChoice
  }

  disconnect() {
    this.picker.destroy()
  }

  broadCastChoice() {
    // send message
    emitter.emit("selected:color", {
      name: this.element.dataset.colorName,
      color: this.element.dataset.color,
    })

  }

  updateColor(color) {
    const colorLength = color.hex.length;

    // # + 123456 + aa
    if (colorLength != 9) {
      throw new Error(`Expected the color hex length to be 9, but it was ${colorLength}`)
    }

    // # + 123456
    const colorWithoutAlpha = color.hex.slice(0, 7);
    this.element.dataset.color = colorWithoutAlpha
    this.previewTarget.style.backgroundColor = colorWithoutAlpha


    // TODO: add this back in so that the hex code can be seen by the user once you figure out how to
    // save the text colour value
    //
    // this.previewTarget.innerHTML = colorWithoutAlpha
    //
    // if((color.rgba[0]*0.299)+(color.rgba[1]*0.587)+(color.rgba[2]*0.114)>186) {
    //   this.previewTarget.style.color = '#1B2222'
    // } else {
    //   this.previewTarget.style.color = '#FFFFFF'
    // }
    }
  }

import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';
import Swal from 'sweetalert2';
import Croppie from 'croppie';

export default class extends Controller {

  static targets = ['file', 'label', 'croppie', 'modal', 'upload', 'blob', 'preview', 'customPhotoCheckbox', 'form', 'button', 'weddingProfile', 'validation', 'autosubmit'];

  connect() {
    this.image_url = false
    this.uploading = false
    this.croppieTarget.style.height = "90%"
    this.updateBlobInput()
  }

  disconnect() {
    this.destroy_croppie();
  }

  open_file_browser() {
    this.labelTarget.click();
  }

  updateBlobInput() {
    this.blobTarget.disabled = (this.blobTarget.value == "")
  }

  updateDisabled(event) {
    if (event.target.checked) {
      this.buttonTarget.classList.remove("disabled")
      this.previewTarget.classList.remove("disabled")
      this.weddingProfileTarget.disabled = false
    } else {
      this.buttonTarget.classList.add("disabled")
      this.previewTarget.classList.add("disabled")
      this.weddingProfileTarget.disabled = true
    }
  }

  process() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      let reader = new FileReader();

      reader.onload = (e) => {
        let img = new Image();
        img.src = e.target.result;
        setTimeout(function (){

          self.croppie = new Croppie(self.croppieTarget, {
            enableExif: true,
            viewport: {
              width: 300,
              height: 150,
              type: 'square'
            },
            boundary: {
              width: '100%',
              height: 300
            }
          })

          // bind croppie
          self.modalTarget.classList.remove('hide')
          self.croppie.bind({ url: e.target.result }).then(() => {
            self.croppie.setZoom(0)
            self.image_url = e.target.result
          });
        }, 50, self=this, img);
      }

      reader.readAsDataURL(this.fileTarget.files[0]);
    }

    this.destroy_croppie();
  }

  upload(e) {
    if (this.uploading) {
      return
    }
    this.uploading = true
    this.uploadTarget.disabled = true
    this.validationTarget.textContent = ""
    this.uploadTarget.textContent = "Uploading..."
    this.uploadTarget.classList.add("disabled")

    this.croppie.result({
      type: 'blob',
      format: 'jpeg',
      size: 'original'
    }).then((blob) => {
      blob.name = this.fileTarget.files[0].name

      const uploader = new DirectUpload(blob, this.fileTarget.dataset.directUploadUrl);
      uploader.create((error, uploaded_blob) => {
        if (error) {
          this.validationTarget.textContent = "Sorry, an error occurred when uploading. Please try again"
        } else {
          this.blobTarget.value = uploaded_blob.signed_id
          this.updateBlobInput()
          const uri = URL.createObjectURL(blob)
          this.previewTarget.src = uri
          this.unhidePreviewImage()
          this.modalTarget.classList.add('hide')
          this.destroy_croppie();
        }

        this.uploading = false
        this.uploadTarget.textContent = "Save"
        this.uploadTarget.classList.remove("disabled")

        this.element.closest("form").requestSubmit(this.uploadedTarget)
      })
    })
  }

  cancel(e) {
    this.modalTarget.classList.add('hide');
    this.destroy_croppie();
    this.updateBlobInput()
  }

  destroy_croppie() {
    if (this.croppie && this.croppie.elements) {
      this.croppie.destroy()
    }
  }

  unhidePreviewImage() {
    this.previewTarget.classList.remove('hide');
  }

}

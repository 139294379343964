import { Controller } from 'stimulus';

// Logic for deciding whether or not a user has explicity selected a date during onboarding
export default class extends Controller {
  static targets = ['output'];

  userDidSomething(event) {
    // The user has checked a box, so whatever choice they made is explicit
    const noInputsChecked = Array.from(
      this.element.querySelectorAll("input[type='checkbox']")
    ).every((input) => !input.checked);

    if (event.target.checked) {
      this.outputTarget.value = true;

      // The use has unchecked every box, if they made an explicit choice before, it is
      // no longer so.
    } else if (noInputsChecked) {
      this.outputTarget.value = false;
    }
  }
}

import { Controller } from "stimulus";
import { emitter } from "../../lib/eventBus";

// this is the complement to the remote_color_picker_sender
export default class extends Controller {
  connect() {
    this.handleSelectedColor = this.handleSelectedColor.bind(this)
    emitter.on("selected:color", this.handleSelectedColor)
  }

  disconnect() {
    emitter.off("selected:color", this.handleSelectedColor)
  }

  handleSelectedColor(details) {
    if (details.name != this.element.dataset.colorName ) {
      return
    }

    this.element.value = details.color
    this.element.dispatchEvent(new Event("change"))
  }
}

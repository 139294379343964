import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['allCheckbox', 'rowCheckbox', 'guestGroupIds'];

  checkbox() {
    this.allCheckboxTarget.checked = false
    this.collectSelectedIds()
  }

  allCheckbox(e) {
    this.rowCheckboxTargets.forEach((el) => { el.checked = e.target.checked })
    this.collectSelectedIds()
  }

  collectSelectedIds() {
    let selectedIds = [];
    this.rowCheckboxTargets.forEach((el, _) => {
      if (el.checked) {
        selectedIds.push(el.value)
      }
    });
    this.guestGroupIdsTarget.value = selectedIds.join(' ')
  }

  toggle(e) {
    e.preventDefault()
    this.fieldsTarget.classList.toggle('hide');
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["number", "button"]

  connect() {
    this.checkboxes = document.querySelectorAll("input[type='checkbox']")
    this.updateCount = this.updateCount.bind(this)
    this.updateCount()

    this.checkboxes.forEach(box => box.addEventListener("change", this.updateCount))
  }

  disconnect() {
    this.checkboxes.forEach(box => box.removeEventListener("change", this.updateCount))
  }

  updateCount() {
    let total = 0
    this.checkboxes.forEach(el => { if (el.checked && !el.hasAttribute("readonly")) total += 1 })
    this.numberTarget.textContent = total
 
    if (total == 0) {
      this.buttonTarget.dataset.action = this.buttonTarget.dataset.selectAction
    } else {
      this.buttonTarget.dataset.action = this.buttonTarget.dataset.sendAction
    }
  }

  selectGuests() {
    document.querySelector('.tabs').dispatchEvent(
      new CustomEvent('tabs:switch', {
        detail: {
          index: 1
        }
      })
    )
  }
}

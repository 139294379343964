export function isHTMLSelectElement(el) {
  return el.tagName === 'SELECT';
}

export function isHTMLTextAreaElement(el) {
  return el.tagName === 'TEXTAREA';
}

export function isHTMLInputElement(el) {
  return el.tagName === 'INPUT';
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['detailsSection', 'paymentSection'];

  connect() {
    this.paymentSectionTarget.classList.add('hide')
    this.handleError();
  }

  viewPaymentSection() {
    this.detailsSectionTarget.classList.add('hide')
    this.paymentSectionTarget.classList.remove('hide')
    window.scrollTo(0, 0)
  }

  handleError() {
    const errors = this.element.querySelectorAll(`.input.error, .callout.alert`);
    if (errors.length == 0) { return }

    const firstError = errors[0];
    if (this.paymentSectionTarget.contains(firstError)) {
      this.viewPaymentSection()
    }
  }

}

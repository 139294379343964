import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
  }

  showHideDetails(e) {
    e.preventDefault();
    const contributionID = e.target.closest('[data-contribution]').dataset.contribution

    const detailsRow = document.querySelector(`tr.contribution-details-row[data-contribution="${contributionID}"]`)

    if (detailsRow.classList.contains('hide')) {
      detailsRow.classList.remove('hide')
    }
    else {
      detailsRow.classList.add('hide')
    }

  }

  deleteContribution(e) {
    if (!e.target.hasAttribute('data-confirm-swal') ) {
      const contributionID = e.target.closest('[data-contribution]').dataset.contribution;
      const contributionRow = document.querySelector(`tr.contribution-details-row[data-contribution="${contributionID}"]`);
      const detailsRow = document.querySelector(`tr.contribution-row[data-contribution="${contributionID}"]`);
      contributionRow.classList.add('hide');
      detailsRow.classList.add('hide');
    }
  }
}

import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

/*
  controls the payment method and payment process on the package
  upgrade checkout page
 */
export default class extends Controller {
  static targets = ['submitButton', 'paymentMethodSection'];

  stripeKey = '';
  csrfToken = '';

  connect() {
    let element = this.element;
    let data = element.dataset;
    this.csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content') || '';

    let stripeKey = data.stripeKey;
    if (stripeKey === undefined || stripeKey == null) {
      throw new Error('Stripe API key not found');
    }

    this.stripeKey = stripeKey;

    this.initStripe();
  }

  initStripe() {
    const stripe = Stripe(this.stripeKey);
    const submitButton = this.submitButtonTarget;

    let next_action = document.getElementById('stripe_next_action').value;
    if (next_action.length > 0) {
      this.handleStripeNextAction(stripe, next_action);
      return;
    }

    const elements = stripe.elements();

    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d',
      },
    };

    const card = elements.create('card', {style});
    card.mount('#card-element');

    card.addEventListener('change', ({error}) => {
      const displayError = document.getElementById('card-errors');
      if (error) {
        displayError.textContent = error.message;

        setTimeout(() => { Rails.enableElement(submitButton) }, 500);
      } else {
        displayError.textContent = '';
        document.querySelector('.payment-error').classList.add('hide');
      }
    });

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById('payment_form');
    let self = this;
    form.addEventListener('submit', async (event) => {
      if (this.paymentMethodSectionTarget.classList.contains('hide')) {
        return true;
      }
      event.preventDefault();

      document.querySelector('.payment-error').classList.add('hide');

      const {paymentMethod, error} = await stripe.createPaymentMethod('card', card);

      if (error) {
        // Inform the customer that there was an error.
        const errorElement = document.getElementById('card-errors');
        errorElement.textContent = error.message;

        setTimeout(() => { Rails.enableElement(submitButton) }, 500);

        document.querySelector('.payment-error').classList.remove('hide');
      } else {
        // Send the token to your server.
        stripeTokenHandler(paymentMethod);
      }
    });

    let stripeTokenHandler = (paymentMethod) => {
      // Insert the token ID into the form so it gets submitted to the server
      const form = document.getElementById('payment_form');
      form.appendChild(self.createHiddenInput('stripePaymentMethod', paymentMethod.id));
      form.appendChild(self.createHiddenInput('authenticity_token', this.csrfToken));
      form.submit();
    };
  }

  async handleStripeNextAction(stripe, next_action) {
    let self = this;
    const {error: error, paymentIntent} = await stripe.handleCardAction(next_action);

    if (error) {
      let paymentError = document.querySelector('.payment-error');
      paymentError.classList.add('hide');

      const errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;

      document.getElementById('stripe_next_action').value = '';
      this.initStripe();
      document.getElementById('sca-warning').classList.add('hide');
      document.getElementById('checkout-details').classList.remove('hide');
    } else {
      const form = document.getElementById('payment_form');
      form.appendChild(self.createHiddenInput('stripePaymentIntent', paymentIntent.id));
      form.appendChild(self.createHiddenInput('authenticity_token', this.csrfToken));
      form.submit();
    }
  }

  createHiddenInput(name, value) {
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.setAttribute('name', name);
    hiddenInput.setAttribute('value', value);
    return hiddenInput;
  }

}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['paymentMethod', 'submit'];

  connect() {
  }

  selectMethod(e) {
    e.preventDefault();
    const paymentMethod = e.target.dataset.payment;
    var link = e.target.dataset.link;
    if (link != null) {
      window.open(link, '_blank').focus();
    }
    this.paymentMethodTarget.value = paymentMethod
    this.submitTarget.click();
  }

}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  change() {
    this.refreshBasketCount();
  }

  refreshBasketCount() {
    let summary = document.querySelector('[data-behaviour=gift-list-basket-summary]');
    fetch(summary.dataset.endpoint)
    .then(response => response.json())
    .then(response => {
      summary.querySelector('.item-count').innerHTML = response.items;
    });
  }
}

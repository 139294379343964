// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// Taken from: https://github.com/gorails-screencasts/dynamic-nested-forms-with-stimulusjs/blob/master/app/javascript/controllers/nested_form_controller.js
// Adaptions by @Sub-Xaero [28 May 2019]
//
// This example controller works with specially annotated HTML like:
//
//  %h4 Tasks
//  %div{ data: { controller: "nested-form" } }
//    %script{ type: "text/x-template", data: { target: "nested-form.template" } }
//      = f.simple_fields_for :tasks, Task.new, child_index: 'NEW_RECORD' do |task|
//        = render "task_fields", f: task
//
//    = f.simple_fields_for :tasks do |task|
//      = render "task_fields", f: task
//
//    .links{ data: { target: "nested-form.links"} }
//      = link_to "Add Task", "#", class: "button eucalyptus-light", data: { action: "click->nested-form#add_association" }
//
//  # _task_fields.html.haml
//  = content_tag :div, class: "nested-fields", data: { new_record: f.object.new_record? } do
//    .form-group
//      = f.label :description
//      = f.text_field :description, class: 'form-control'
//      %small= link_to "Remove", "#", data: { action: "click->nested-form#remove_association" }
//
//    = f.hidden_field :_destroy

import { Controller } from 'stimulus';
import throttle from "lodash.throttle"

export default class NestedFormsController extends Controller {

  static targets = ['links', 'template', 'singularAdd', 'pluralAdd'];

  connect() {
    this.add_association = throttle(this.add_association, 250, { trailing: false })

    let data = (this.element).dataset;
    this.wrapperClass = data.wrapperClass || 'nested-fields';
    this.newRecordPlaceholder = data.newRecordPlaceholder || 'NEW_RECORD';

    let maxRecords = data.maxRecords;
    if (maxRecords !== undefined) {
      this.maxRecords = Number.parseInt(maxRecords);
    }

    this.numRecords = this.countRecords();
    this.manageMaxRecords();
    this.managePluralButtons();
  }

  add_association(event = null) {
    if (event !== null) {
      event.preventDefault();
    }

    this.linksTarget.querySelectorAll('.alert').forEach(el => {
      el.classList.remove('alert');
      el.classList.add('success');
    });

    // display order has to reset every 14 years or it won't fit in the database
    const displayOrder = (new Date().getTime() % 2147483648).toString()
    let content = this.templateTarget.innerHTML.replace(new RegExp(this.newRecordPlaceholder, 'g'), displayOrder);
    this.linksTarget.insertAdjacentHTML('beforebegin', content);

    this.manageMaxRecords();
    this.managePluralButtons();
    let children = this.element.querySelectorAll('.' + this.wrapperClass);
    return children[children.length - 1];
  }

  remove_association(event) {
    event.preventDefault();

    let target = event.target;
    if (!target) {
      throw new Error('Event target is null');
    }

    let wrapper = target.closest('.' + this.wrapperClass);
    this.remove(wrapper);
  }

  remove(wrapper) {
    // New records are simply removed from the page
    if (wrapper && wrapper.dataset.newRecord === 'true') {
      wrapper.remove();
      this.manageMaxRecords();
      this.managePluralButtons();

      // Existing records are hidden and flagged for deletion
    } else if (wrapper) {
      let existing = wrapper.querySelector('input[name*=\'_destroy\']');
      if (existing && existing instanceof HTMLInputElement) {
        existing.value = '1';
      }
      wrapper.style.display = 'none';
      this.manageMaxRecords();
      this.managePluralButtons();
    } else {
      throw new Error('Wrapper not found');
    }
  }

  restore(wrapper) {
    // Existing records are hidden and flagged for deletion
    if (wrapper) {
      let existing = wrapper.querySelector('input[name*=\'_destroy\']');
      if (existing && existing instanceof HTMLInputElement) {
        existing.value = '';
      }
      wrapper.style.display = 'block';
      this.manageMaxRecords();
    } else {
      throw new Error('Wrapper not found');
    }
  }

  manageMaxRecords() {
    this.numRecords = this.countRecords();
    if (this.maxRecords != null) {
      if (this.maxRecords === this.numRecords) {
        this.linksTarget.classList.add('hide');
      } else {
        this.linksTarget.classList.remove('hide');
      }
    }
  }

  managePluralButtons() {
    this.numRecords = this.countRecords();
    if (this.hasPluralAddTarget && this.hasSingularAddTarget) {
      if (this.numRecords < 1) {
        this.singularAddTarget.classList.remove('hide');
        this.pluralAddTarget.classList.add('hide');
      } else {
        this.singularAddTarget.classList.add('hide');
        this.pluralAddTarget.classList.remove('hide');
      }
    }
  }

  countRecords() {
    let count = 0;
    this.element.querySelectorAll('.' + this.wrapperClass).forEach((wrapper) => {
      if (!hasExistingRecordInNestedFormBeenDeleted(wrapper)) {
        count += 1;
      }
    });
    return count;
  }

}

export function initNestedFormsConnection(application, selector) {
  let nestedFormsElement = document.querySelector(selector);
  if (nestedFormsElement == null) {
    throw  new Error(`Expected a nested forms element to be present for selector ${selector}`);
  }
  let nestedFormsController = application.getControllerForElementAndIdentifier(nestedFormsElement, 'nested-form');
  if (nestedFormsController == null) {
    throw  new Error(`Expected a nested forms controller to be present on element for selector ${selector}`);
  }
  return nestedFormsController;
}

export function addRecordToNestedForm(nestedFormsController, row) {
  if (!row.fields.hasOwnProperty(row.identifier)) {
    throw new Error(`Identifying field '${row.identifier}' not present in row`);
  }

  let existingInput = existingRecordInNestedForm(nestedFormsController, row.identifier, row.fields[row.identifier]);
  if (existingInput != null) {
    let destroyed = hasExistingRecordInNestedFormBeenDeleted(existingInput);
    if (destroyed) {
      nestedFormsController.restore(existingInput);
    }
    return;
  }

  let new_row = nestedFormsController.add_association();
  if (new_row == null) {
    throw new Error('New row was not created');
  }
  for (let field_name in row.fields) {
    if (row.fields.hasOwnProperty(field_name)) {
      let value = row.fields[field_name];
      let input = new_row.querySelector(`input[name*="[${field_name}]"]`);
      if (input == null) {
        throw new Error(`Could not find an input with field name '${field_name}' in the nested form row`);
      }
      (input).value = value;
    }
  }
}

export function removeRecordFromNestedForm(
  nestedFormsController,
  identifyingFieldName,
  fieldValue,
) {
  let wrapper = existingRecordInNestedForm(nestedFormsController, identifyingFieldName, fieldValue);
  if (wrapper != null) {
    nestedFormsController.remove(wrapper);
  }
}

export function existingRecordInNestedForm(
  nestedFormsController,
  identifyingFieldName,
  fieldValue,
) {
  let element = Array.from(nestedFormsController.element.querySelectorAll(`input[name*="[${identifyingFieldName}]"]`)).find(
    (el) => {
      return (el).value === fieldValue;
    });
  if (element === undefined) {
    return null;
  }
  return (element).closest(`.${nestedFormsController.wrapperClass}`);
}

export function fieldInWrapper(wrapper, fieldIdentifier) {
  return wrapper.querySelector(`input[name*="[${fieldIdentifier}]"]`);
}

export function hasExistingRecordInNestedFormBeenDeleted(wrapper) {
  let destroy_field = fieldInWrapper(wrapper, '_destroy');
  if (destroy_field == null) {
    return false;
  }
  return destroy_field.value === '1';
}

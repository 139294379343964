import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    if (!window.location.hash) { return; }

    var content_block_element = document.getElementById(window.location.hash.substr(1));

    if (!content_block_element) { return; }

    if (document.querySelector('.website-tabs, .menu-container')) {
      this.handleTabLayout(content_block_element);
    }

    setTimeout(function() {
      window.scroll(0, content_block_element.offsetTop);
    }, 1000);
  }

  handleTabLayout(content_block_element) {
    var closest_tab = content_block_element.closest('div[data-target^="tabs"]');

    // tab content target attribute has the format 'tabs.guestInformation'
    // so we need to remove the 'tabs.' part and capitalise the first letter
    // to turn it into the required tabs#showGuestInformation action attribute
    // to find the appropriate tab selector
    var tab_slug = closest_tab.dataset.target.substr(5);
    var tab_selector = document.querySelector('[data-action="tabs#show' + tab_slug.substr(0, 1).toUpperCase() + tab_slug.substr(1) + '"]');

    setTimeout(function() {
      tab_selector.click();
    }, 1000)
  }
}

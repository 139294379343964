import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['otherCheckbox', 'customEventsContainer'];

  connect() {
    this.customEventsContainerTarget.classList.add('hide');
  }

  toggleCustomArea(e) {
    this.customEventsContainerTarget.classList.toggle('hide', !e.target.checked);
  }

  toggleNotSureCheckbox(e) {
    if (e.target.checked) {
      Array.from(this.element.querySelectorAll('input')).map((i) => {i.checked = false});
      e.target.checked = true;
    }
  }


}

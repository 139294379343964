// this is for submitting a form when the submit button is in a header or
// something silly like that

// because it's not guaranteed that the form will be a descendant of the button,
// use a selector to determine which one

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    const selector = this.element.dataset.selector
    this.form = document.querySelector(selector)
  }

  submit(e) {
    // Only call #disableButton if the submit event target is a button
    if (this.isButton(e.target)) {
      this.disableButton(e.target);
    }

    if (this.form.requestSubmit) {
      this.form.requestSubmit()
    } else {
      if (window.Rollbar) {
        window.Rollbar.debug("no requestSubmit, falling back to form.submit()")
      }
      this.form.submit()
    }
  }

  disableButton(element) {
    element.disabled = true;
    element.textContent = "Submitting...";
  }

  isButton(element) {
    return element.tagName.toLowerCase() === "button";
  }
}

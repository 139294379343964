import Flatpickr from 'stimulus-flatpickr';

export default class extends Flatpickr {
  change(selectedDates, dateStr, instance) {
    this.element.dispatchEvent(new CustomEvent(
      "flatpickr:changed",
      {
        bubbles: true,
        detail: {
          selectedDates,
          dateStr,
          instance: instance.config.dateFormat,
        }
      }
    ))
  }
}

// this controller is a stripped-down copy of the rsvp-group--transfer controller
// it controls the confirmation modal when bulk assigning unassigned guests

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["assignButton", "confirm", "guestsCopy", "groupCopy", "warningCopy"]

  connect() {
    // track how many guests are selected
    this.updateGuestsSelected = this.updateGuestsSelected.bind(this)
    this.updateAssignButtonDisabled = this.updateAssignButtonDisabled.bind(this)
    this.updateCopy = this.updateCopy.bind(this)
    this.assignedGuests = this.element.querySelectorAll("[name='rsvp_group[individual_guest_ids][]']")
    this.assignedGuests.forEach(guest => guest.addEventListener("change", this.updateGuestsSelected.bind(this)))
    this.guestsSelected = Array.from(this.assignedGuests).reduce((acc, x) => x.checked ? acc + 1 : acc, 0)
    this.assignedGuestsSelected = Array.from(this.assignedGuests).reduce((acc, x) => x.checked && x.dataset.assigned == "1" ? acc + 1 : acc, 0)
    this.updateAssignButtonDisabled()
    this.updateCopy()
  }

  // we checked or unchecked a guest, update the count accordingly
  updateGuestsSelected(event) {
    if (event.target.checked) {
      this.guestsSelected += 1

      if (event.target.dataset.assigned == "1") {
        this.assignedGuestsSelected += 1
      }
    } else {
      this.guestsSelected -= 1

      if (event.target.dataset.assigned == "1") {
        this.assignedGuestsSelected -= 1
      }
    }
    this.updateAssignButtonDisabled()
    this.updateCopy()
  }

  // if we have no guests selected, the button should be disabled
  // if we have at least one guest selected, the button should be enabled
  updateAssignButtonDisabled() {
    if (this.guestsSelected <= 0) {
      this.assignButtonTarget.setAttribute("disabled", "")
    } else {
      this.assignButtonTarget.removeAttribute("disabled", "")
    }
  }

  updateCopy() {
    this.guestsCopyTarget.textContent = this.guestsSelected == 1 ? "1 guest" : `${this.guestsSelected} guests`
    const groupName = this.element.dataset.groupName
    this.groupCopyTarget.textContent = `${groupName} Group`
    this.warningCopyTarget.classList.toggle("hide", this.assignedGuestsSelected == 0)
  }

  showConfirmModal(event) {
    if (event.target.hasAttribute("disabled", "")) {
      event.preventDefault()
      return
    }
    this.confirmTarget.dispatchEvent(new CustomEvent("open", {}))
  }
}

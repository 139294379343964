
import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import debounce from 'lodash.debounce';
import { emitter } from "../../lib/eventBus"

export default class extends Controller {
  connect() {
    this.allInputs = 'input, select, textarea, trix-editor' 
    this.submit = this.submit.bind(this)
    this.submit = debounce(this.submit, 250)

    this.element.querySelectorAll(this.allInputs)
      .forEach(el => {
        el.addEventListener('change', this.submit)
        el.addEventListener('keyup', this.submit)
      })
  }

  disconnect() {
    this.element.querySelectorAll(this.allInputs)
      .forEach(el => {
        el.removeEventListener('change', this.submit)
        el.removeEventListener('keyup', this.submit)
      })
  }

  submit(e) {
    Rails.fire(this.element, 'submit')
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['events', 'hotels'];

  change(e) {
    e.preventDefault();
    this.hotelsTarget.classList.add('hide');
    this.eventsTarget.classList.remove('hide');
  }

  cancel(e) {
    e.preventDefault();
    this.eventsTarget.classList.add('hide');
    this.hotelsTarget.classList.remove('hide');
  }

  removeRecommendation(e) {
    let res = document.querySelector('.hotel-result[data-hotel="' + e.target.dataset.hotel + '"]');
    res.parentNode.remove();
  }
}

// extends the stimulus tabs package to set the anchor in the address bar
// and select the tab pointed to by the anchor on load
//
// we should get rid of this file & this package next time we refactor,
// there's no reason to depend on npm for something as simple as tabs

import TabsController from 'stimulus-tabs';

export default class extends TabsController {
  connect() {
    const anchor = new URL(window.location.href).hash.slice(1)
    if (anchor !== "") {
      this.element.dataset.tabsSelectedTab = anchor
    }

    super.connect()
  }

  selected() {
    if (window.frameElement !== null) {
      const currentURL = new URL(window.location.href)
      currentURL.hash = this.element.dataset.tabsSelectedTab
      window.frameElement.src = currentURL.toString()
    }
  }
}

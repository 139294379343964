import { Controller } from 'stimulus';
import { clearFormInputs, clearInput, clearInputs, clearNestedForms, clearSelect } from '../../../lib/clearInputs';

export default class YesNoHideController extends Controller {

  static targets = ['hide', 'show'];
  clearInputsOnHide = false;
  yesTarget = null;
  noTarget = null;
  clearInputs = clearInputs.bind(this);
  clearNestedForms = clearNestedForms.bind(this);
  clearFormInputs = clearFormInputs.bind(this);
  clearInput = clearInput.bind(this);
  clearSelect = clearSelect.bind(this);

  connect() {
    let self = this;

    this.clearInputsOnHide = this.element.dataset.clearInputsOnHide === 'true' || false;

    this.toggleVisibility();
    this.showTarget.addEventListener('change', () => self.toggleVisibility());
    if (this.hasHideTarget) {
      this.hideTarget.addEventListener('change', () => self.toggleVisibility());
    }
  }

  toggleVisibility(remote = false) {
    if (!remote) {
      this.handleRadios();
    }
    // Yes ticked
    if (this.showTarget.checked) {
      this.yesTarget !== null ? this.yesTarget.classList.remove('hide') : null;
      this.noTarget !== null ? this.noTarget.classList.add('hide') : null;
      if (this.noTarget && this.clearInputsOnHide) {
        this.clearInputs(this.noTarget);
      }
    } else {
      // If there is a No option, and neither yes-nor-no is ticked, hide everything
      if (this.hasHideTarget && !this.hideTarget.checked) {
        this.yesTarget !== null ? this.yesTarget.classList.add('hide') : null;
        this.noTarget !== null ? this.noTarget.classList.add('hide') : null;
        if (this.yesTarget && this.clearInputsOnHide) {
          this.clearInputs(this.yesTarget);
        }
        if (this.noTarget && this.clearInputsOnHide) {
          this.clearInputs(this.noTarget);
        }
      } else {
        // Yes isnt ticked, and either No is ticked or there is no explicit No option. Show any existing No targets and clear any yesTargets
        this.yesTarget !== null ? this.yesTarget.classList.add('hide') : null;
        this.noTarget !== null ? this.noTarget.classList.remove('hide') : null;
        if (this.yesTarget && this.clearInputsOnHide) {
          this.clearInputs(this.yesTarget);
        }
      }
    }
  }

  handleRadios() {
    if (this.showTarget.type === 'radio') {
      let formName = this.showTarget.name;
      let form = this.showTarget.closest('form');
      if (form == null) {
        throw new Error('No form found');
      }
      form.querySelectorAll(`[type="radio"][name~="${formName}"]`).forEach(el => {
        if (el !== this.showTarget) {
          let longRange = this.application.getControllerForElementAndIdentifier(
            el,
            'yes-no-hide--long-range',
          );
          let local = this.application.getControllerForElementAndIdentifier(
            el,
            'yes-no-hide--local',
          );
          let controller = longRange || local || null;
          if (controller == null) {
            return;
          }
          controller.toggleVisibility(true);
        }
      });
    }
  }

}

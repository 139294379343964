import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['name', 'nameInput'];

  connect() {
    this.setName(0);
    this.setName(1);
  }

  updateName(e) {
    this.setName(e.target.dataset.partner);
  }

  setName(idx) {
    let name = this.nameInputTargets[idx].value;

    if (name === '') {
      name = 'Partner ' + (parseInt(idx) + 1);
    }

    this.nameTargets[idx].innerHTML = name;
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.param = this.element.dataset.param ?? "tab"
    const config = { attributes: true };
    const tabs = Array.from(
      this.element.querySelectorAll(
        ':scope > [data-tabs-headers] > [data-tabs-header]'
      )
    );

    this.observers = tabs.map((tab, index) => {
      const isSelected = () => tab.classList.contains('selected')
      const writeParam = () => {
        const url = new URL(window.location)
        url.searchParams.set(this.param, index)
        history.pushState(null, null, url)
      }

      const observer = new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.attributeName == 'class' && isSelected()) writeParam()
        }
      });

      observer.observe(tab, config);

      return observer;
    });
  }

  disconnect() {
    this.observers.forEach((observer) => observer.disconnect());
  }
}

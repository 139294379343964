import { Controller } from 'stimulus';

/*
  Controls much of the checkout page for upgrading packages in the Manage app.

  It validates and applies a voucher code, then displays the new
  price. It _does not redeem_ a voucher. (Redemption should happen
  server-side on form submission.)

  Also hides payment methods (Stripe) from the user if the voucher makes the
  purchase free of charge.
*/
export default class extends Controller {
  static targets = [
    'checkoutSubmit',
    'input',
    'inputWrapper',
    'orderIsFreeSection',
    'paymentMethodSection',
    'redemptionError',
    'redemptionError',
    'redemptionInput',
    'redemptionPrice',
    'redemptionText',
    'redemptionWrapper',
    'showLinkWrapper',
    'total',
    'validate',
    'voucherWrapper',
  ];
  boundChangeHandler = this.handleChange.bind(this);
  boundSubmitHandler = this.handleSubmit.bind(this);

  price = 0;
  total = 0;
  discount = null;

  connect() {
    let element = this.element;
    let dataset = element.dataset;
    let url = dataset.url;
    if (url == null) {
      throw new Error('Could not find data attribute `url` on Element');
    }
    this.url = url;

    let price = dataset.price;
    if (price == null) {
      throw new Error('Could not find data attribute `price` on Element');
    }
    this.price = Number.parseFloat(price);
    this.total = this.price;

    this.inputTarget.addEventListener('keyup', this.boundChangeHandler);
    this.validateTarget.addEventListener('click', this.boundSubmitHandler);

    this.updateView();
  }

  showVoucherSection() {
    this.voucherWrapperTarget.classList.remove('hide');
    this.showLinkWrapperTarget.classList.add('hide');
    this.inputTarget.focus();
  }

  hideVoucherSection() {
    this.voucherWrapperTarget.classList.add('hide');
    this.showLinkWrapperTarget.classList.remove('hide');
  }

  disconnect() {
    this.inputTarget.removeEventListener('keyup', this.boundChangeHandler);
    this.validateTarget.removeEventListener('click', this.boundSubmitHandler);
  }

  handleChange(_event) {
    this.discount = null;
    this.updateView();
    this.hideError();
  }

  /* hide credit card or other payment method if purchase is free */
  hidePaymentMethodSectionMaybe() {
    if (this.total <= 0) {
      this.paymentMethodSectionTarget.classList.add('hide');
      this.orderIsFreeSectionTarget.classList.remove('hide');
    } else {
      this.orderIsFreeSectionTarget.classList.add('hide');
      this.paymentMethodSectionTarget.classList.remove('hide');
    };
  }

  /* depends on whether have a balance to pay */
  updatePaymentTexts() {
    if (this.total <= 0) {
      this.checkoutSubmitTarget.value = 'Place order';
    } else {
      this.checkoutSubmitTarget.value = 'Place order and pay';
    };
  }

  async handleSubmit(event = null) {
    let payload = new FormData();
    let csrfTokenElement = document.querySelector('[name="csrf-token"]');

    if (csrfTokenElement) {
      // Only submit authenticity token if it is available (it is not
      // in tests).  If it is missing in production, client will
      // proceed as normal, and server will error with standard Rails
      // CSRF protection as intended.
      payload.append('authenticity_token', csrfTokenElement.getAttribute('content') || '');
    }
    payload.append('voucher_code', this.inputTarget.value);

    let results = await fetch(this.url, {
      method: 'post',
      body: payload,
    });

    if (!results.ok) {
      this.showError('This code is not valid');
      return;
    }

    this.discount = await results.json();
    this.updateView();
  }

  showError(msg) {
    this.inputWrapperTarget.classList.add('error');
    this.redemptionErrorTarget.classList.remove('hide');
    this.redemptionErrorTarget.innerText = msg;
  }

  hideError() {
    this.inputWrapperTarget.classList.remove('error');
    this.redemptionErrorTarget.classList.add('hide');
    this.redemptionErrorTarget.innerText = '';
  }

  updateView() {
    if (this.discount !== null) {
      this.redemptionWrapperTarget.classList.remove('hide');
      let {id, code, fixed_savings, savings_multiplier} = this.discount;

      if (fixed_savings !== null) {
        this.total = this.price - fixed_savings;
      } else if (savings_multiplier !== null) {
        this.total = this.price - (this.price * savings_multiplier);
      }
      this.total = Math.max(this.total, 0);
      let savings = this.price - this.total;

      this.redemptionTextTarget.innerText = `Voucher: ${code}`;
      this.redemptionPriceTarget.innerText = `- £${savings.toFixed(2)}`;
      this.redemptionInputTarget.value = id;

      this.inputTarget.value = '';
    } else {
      this.redemptionWrapperTarget.classList.add('hide');
      this.total = this.price;
      this.redemptionTextTarget.innerText = '';
      this.redemptionPriceTarget.innerText = '';
      this.redemptionInputTarget.value = '';
    }
    this.updateTotal();
    this.hidePaymentMethodSectionMaybe();
    this.updatePaymentTexts();
  }

  updateTotal() {
    this.totalTarget.innerText = `£${this.total.toFixed(2)}`;
  }

}

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['url'];

  get url() {
    return this.urlTarget.href;
  }

  click(e) {
    Turbolinks.visit(this.url);
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['preview', 'previewLoading'];

  boundHandler = this.preview.bind(this);

  connect(){
    var el = document.querySelector("#import_file")
    el.addEventListener('change', this.boundHandler);
  }

  preview(e){
    e.preventDefault()

    this.previewLoadingTarget.classList.remove('hide');

    var file = e.target.files[0];
    var data = new FormData()
    data.append('authenticity_token', document.querySelector("meta[name='csrf-token']").getAttribute('content'))
    data.append('import[file]', file)

    fetch(this.element.dataset.previewEndpoint, {
        method: 'POST',
        body: data
      }
    )
    .then(response => response.text())
    .then(html => this.previewTarget.innerHTML = html)
    .finally(() => this.previewLoadingTarget.classList.add('hide'));
  }
}

import RemoteModalController from "./remote_modal_controller"

export default class extends RemoteModalController {
  connect() {
    this.kill = this.kill.bind(this)
    this.killIfEscapePressed = this.killIfEscapePressed.bind(this)
    this.killIfClickedOutside = this.killIfClickedOutside.bind(this)
    this.open = this.open.bind(this)
    this.element.addEventListener("open", this.open)
    this.element.addEventListener("kill", this.kill)
  }

  disconnect() {
    this.element.removeEventListener("open", this.open)
    this.element.removeEventListener("kill", this.kill)
    this.kill()
  }

  open() {
    document.addEventListener("keydown", this.killIfEscapePressed)
    document.body.addEventListener("pointerdown", this.killIfClickedOutside)
    document.body.style.overflow = "hidden"
    this.element.classList.remove("hide")
  }

  kill() {
    document.removeEventListener("keydown", this.killIfEscapePressed)
    document.body.removeEventListener("pointerdown", this.killIfClickedOutside)
    document.body.style.overflow = null
    this.element.classList.add("hide")
  }
}

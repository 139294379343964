import { Controller } from "stimulus";

// based on https://www.youtube.com/watch?v=jfYWwQrtzzY
export default class extends Controller {

  connect() {
    this.handleDrag = this.handleDrag.bind(this)
    this.handleDragOver = this.handleDragOver.bind(this)
    this.handleDragEnd = this.handleDragEnd.bind(this)

    this.draggables = this.element.querySelectorAll("[draggable]")
    this.draggables.forEach((element) => {
      element.addEventListener("dragstart", this.handleDrag)
    })

    this.element.addEventListener("dragover", this.handleDragOver)
    this.element.addEventListener("dragend", this.handleDragEnd)
  }

  disconnect() {
    this.draggables.forEach((element) => {
      element.addEventListener("dragstart", this.handleDrag)
    })

    this.element.removeEventListener("dragover", this.handleDragOver)
    this.element.removeEventListener("dragend", this.handleDragEnd)
  }

  enableDragging(_event) {
    this.canStartDrag = true
  }

  handleDrag(event) {
    if (!this.canStartDrag) {
      event.preventDefault()
      return
    }

    this.canStartDrag = false
    this.dragging = event.target
    event.target.classList.add("dragging")
  }

  handleDragOver(event) {
    // this will be null if we have dragged an element into the wrong list
    if (this.dragging == null) {
      return
    }

    event.preventDefault()
    const afterElement = this.getDragAfterElement(event.clientY)

    if (afterElement == null) {
      this.element.appendChild(this.dragging)
    } else {
      this.element.insertBefore(this.dragging, afterElement)
    }
  }

  getDragAfterElement(y) {
    return [...this.element.querySelectorAll("[draggable]")]
      .filter(element => element !== this.dragging)
      .reduce((acc, element) => {
        const box = element.getBoundingClientRect()
        const offset = y - box.top - box.height / 2

        if (offset < 0 && offset > acc.offset ) {
          return { offset: offset, element: element }
        } else {
          return acc
        }
      }, { offset: Number.NEGATIVE_INFINITY }).element
  }

  handleDragEnd() {
    this.dragging.classList.remove("dragging")
    this.dragging = null;
  }
}

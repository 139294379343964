import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [];

  connect() {
    let element = this.element;
    let dataset = element.dataset;
    let seconds = dataset.seconds;
    if (seconds == null) {
      throw new Error('Could not find data attribute `seconds` on Element');
    }

    this.timeout = setTimeout(() => {
      this.element.remove();
    }, seconds * 1000);
  }

  disconnect() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

}

import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';
import Swal from 'sweetalert2';
import Croppie from 'croppie';

export default class extends Controller {

  static targets = ['template', 'label', 'intro', 'edit', 'file', 'blob', 'croppie', 'upload', 'delete'];

  connect() {
    this.image_url = false
    this.uploading = false

    if (!this.hasTemplateTarget) {
      this.disable_upload()
      this.init_croppie()
    }
  }

  disconnect() {
    if (this.croppie) {
      this.croppie.destroy()
    }
  }

  start(e){
    Swal.fire({
      title: "Your couple photo",
      html: this.templateTarget.innerHTML,
      confirmButtonText: "Upload",
      showCancelButton: false,
      showConfirmButton: false,
      buttonsStyling: false,
      customClass: {
        container: 'overlay-background',
        popup: 'overlay-content',
        confirmButton: 'button',
        cancelButton: 'button outlined red'
      }
    })
  }

  init_croppie() {
    if (this.croppie) { return }

    this.croppie = new Croppie(this.croppieTarget, {
      enableExif: true,
      enableOrientation: true,
      viewport: {
        width: 290,
        height: 290,
        type: 'circle'
      },
      boundary: {
        width: '100%',
        height: 300
      }
    })
  }

  resize() {
    if (!this.croppie) { return }

    this.croppie.bind({ url: this.image_url, orientation: 1 }).then(() => {
      this.croppie.setZoom(0)
    })
  }

  open_file_browser() {
    this.labelTarget.click()
  }

  rotate() {
    if (!this.croppie) { return }

    this.croppie.rotate(90)
  }

  disable_upload() {
    this.uploadTarget.setAttribute('disabled', 'disabled')
  }

  begin_upload() {
    this.disable_upload()
    this.uploadTarget.classList.add('in-progress')
  }

  enable_upload() {
    this.uploadTarget.classList.remove('in-progress')
    this.uploadTarget.removeAttribute('disabled')
  }

  process() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      let reader = new FileReader();

      reader.onload = (e) => {
        if (this.hasIntroTarget) {
          this.introTarget.remove()
          this.editTarget.classList.remove('hide')
        }

        // bind croppie
        this.croppie.bind({ url: e.target.result }).then(() => {
          this.image_url = e.target.result
          Swal.resetValidationMessage()
          this.enable_upload()
          this.croppie.setZoom(0)
        });
      }

      reader.readAsDataURL(this.fileTarget.files[0]);
    }
  }

  cancel() {
    Swal.close()
  }

  delete() {
    this.deleteTarget.setAttribute('disabled', 'disabled')
    this.deleteTarget.classList.add('in-progress')
  }

  upload(e) {
    if (this.uploading) {
      return
    }
    this.uploading = true

    Swal.resetValidationMessage()

    if (!this.image_url) {
      Swal.showValidationMessage("Please select a photo first")

    } else {
      this.croppie.result({
        type: 'blob',
        format: 'jpeg',
        quality: 0.9,
        size: { width: 400, height: 400 },
        circle: false // we'll crop it with CSS
      }).then((blob) => {
        blob.name = this.fileTarget.files[0].name

        this.begin_upload()

        const uploader = new DirectUpload(blob, this.fileTarget.dataset.directUploadUrl);
        uploader.create((error, uploaded_blob) => {
          if (error) {
            Swal.showValidationMessage("Sorry, an error occurred when uploading. Please try again")
            this.enable_upload()

          } else {
            this.blobTarget.value = uploaded_blob.signed_id
            Rails.fire(this.element, 'submit')
          }

          this.uploading = false
        })
      })
    }
  }
}

import YesNoHideController from './yes_no_hide_controller';

export default class extends YesNoHideController {

  static targets = ['hide', 'show'];

  yesSelector = '';
  noSelector = '';

  connect() {
    let self = this;
    let data = this.element.dataset;

    let yesSelector = data.yesSelector;
    let noSelector = data.noSelector;

    if ((yesSelector === undefined || yesSelector.trim() === '') && (noSelector === undefined || noSelector.trim() === '')) {
      throw new Error('Expected data-yes-selector and/or data-no-selector to be present on controller element');
    }

    let yesTarget = null;
    let noTarget = null;
    if (yesSelector !== undefined && yesSelector.trim() !== '') {
      this.yesSelector = yesSelector;
      yesTarget = document.querySelector(this.yesSelector);
    }

    if (noSelector !== undefined && noSelector.trim() !== '') {
      this.noSelector = noSelector;
      noTarget = document.querySelector(this.noSelector);
    }

    if (yesTarget == null && noTarget == null) {
      throw new Error('Neither Selector matches any elements in the DOM');
    }

    this.yesTarget = yesTarget ? yesTarget : null;
    this.noTarget = noTarget ? noTarget : null;
    super.connect();
  }

}

import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static targets = [];

  connect() {
    let element = this.element;
    let dataset = element.dataset;

    this.multiple = element.multiple;

    this.choices = new Choices(this.element, {
      duplicateItemsAllowed: 'duplicateItemsAllowed' in dataset ? dataset.duplicateItemsAllowed : false,
      removeItemButton: 'removeItemButton' in dataset ? dataset.removeItemButton : this.multiple,
      position: 'bottom',
    });
  }
}

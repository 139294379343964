import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "preview", "clear", "hint"]

  connect() {
    // when a new file is selected, update the preview
    this.reader = new FileReader()
    this.reader.addEventListener("load", () => {
      this.element.style.setProperty("--background-image", `url(${this.reader.result})`)
    })

    // did we start with a file uploaded? if so, show the preview and
    // enable the clear button
    this.initialURL = this.element.dataset.initialUrl
    if (this.initialURL) {
      this.element.style.setProperty("--background-image", `url(${this.initialURL})`)
      this.initialURL = undefined
    } else {
      this.element.style.setProperty("--clear-display", "none")
      this.preview()
    }
  }

  select() {
    this.inputTarget.setAttribute("type", "file")
    this.inputTarget.click()
  }

  preview() {
      const hasFile = this.inputTarget.files && this.inputTarget.files.length > 0
      if (!hasFile) return

      const fileSize = this.inputTarget.files[0].size
      const meg = 1000000
      if (fileSize / meg > 10) {
        console.log("large image", fileSize / meg, "MB")
        this.hintTarget.textContent = "Warning: very big file!"
      } else {
        this.hintTarget.textContent = "Click to select file."
      }

      this.reader.readAsDataURL(this.inputTarget.files[0])
      this.element.style.setProperty("--clear-display", "inline")
  }

  clear(e) {
    e.stopImmediatePropagation()
    this.inputTarget.removeAttribute("type")
    this.inputTarget.value = "_destroy"
    this.element.style.removeProperty("--background-image")
    this.element.style.setProperty("--clear-display", "none")
    this.inputTarget.dispatchEvent(new CustomEvent("change", {}))
  }
}

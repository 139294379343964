import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filter", "blank"]

  connect() {
    // we need to have a base selector to make sure we are only hiding intended elements,
    // otherwise we would hide everything on the page when all filters are disabled...
    this.baseSelector = this.element.dataset.selector

    // always start off with everything selected
    this.state = new Map()
    this.filterTargets.forEach(filter => {
      filter.checked = true
      this.state.set(filter.dataset.selector, true)
    })
    this.blankTarget.classList.add("hide")
  }

  // to be shown, an element must have at least one enabled selector and no disabled selector
  showSelector() {
    let anyEnabled = ""
    let noDisabled = ""
    for (const [selector, enabled] of this.state.entries()) {
      if (enabled) {
        if (anyEnabled != "") anyEnabled += ", "
        anyEnabled += selector
      } else {
        noDisabled += `:not(${selector})`
      }
    }
    return `${this.baseSelector}:is(${anyEnabled})${noDisabled}`
  }

  // to be hidden, an element must have at least one disabled selector or no enabled selector
  hideSelector() {
    let anyDisabled = ""
    let noEnabled = ""
    for (const [selector, enabled] of this.state.entries()) {
      if (enabled) {
        noEnabled += `:not(${selector})`
      } else {
        if (anyDisabled != "") anyDisabled += ", "
        anyDisabled += selector
      }
    }
    return `${this.baseSelector}:is(${anyDisabled}, ${noEnabled})`
  }

  toggle(event) {
    const key = event.target.dataset.selector
    this.state.set(key, event.target.checked)

    const toShow = this.element.querySelectorAll(this.showSelector())
    this.blankTarget.classList.toggle("hide", toShow.length > 0)
    toShow.forEach(showing => showing.classList.toggle("hide", false))

    const toHide = this.element.querySelectorAll(this.hideSelector())
    toHide.forEach(hiding => hiding.classList.toggle("hide", true))
  }
}

import { fireHTMLEvent } from './events';
import { isHTMLInputElement, isHTMLSelectElement, isHTMLTextAreaElement } from './inputType';

export function clearInputs(parent) {
  let nestedFormControllers = parent.querySelectorAll('[data-controller~="nested-form"]');

  let clearedSpecial = false;

  // If there is a NestedForms setup nested in this domain, deal with it in the special way
  if (!!parent.dataset.controller && parent.dataset.controller.indexOf('nested-form') !== -1) {
    this.clearNestedForms(parent);
    clearedSpecial = true;
  } else if (nestedFormControllers.length !== 0) {
    nestedFormControllers.forEach(el => this.clearNestedForms(el));
    clearedSpecial = true;
  }

  // If form has not already been cleared by the special cases. Clear remaining inputs
  if (!clearedSpecial) {
    this.clearFormInputs(parent);
  }
}

// Special method for dealing with a request to clear nested inputs in a domain that has a nested NestedForms setup
export function clearNestedForms(controllerRootEl) {
  let nestedFormsController = this.application.getControllerForElementAndIdentifier(
    controllerRootEl,
    'nested-form',
  );
  if (!nestedFormsController) {
    // If controller not initialised yet (likely, YesNoController comes first in DOM), or could not be retrieved (unlikely)
    // revert to standard clearing of inputs
    this.clearFormInputs(controllerRootEl);
    return;
  }
  let wrapperClass = nestedFormsController.wrapperClass;

  let nestedForms = controllerRootEl.querySelectorAll('.' + wrapperClass);
  nestedForms.forEach(el => nestedFormsController.remove(el));
}

export function clearFormInputs(parent) {
  parent.querySelectorAll('input, textarea, select').forEach(el => {
    if (isHTMLSelectElement(el)) {
      this.clearSelect(el);
    } else if (isHTMLTextAreaElement(el) || isHTMLInputElement(el)) {
      this.clearInput(el);
    } else {
      throw new Error('Unsupported input type');
    }
    fireHTMLEvent(el, 'change');
  });
}

// Clear <Input>s and <TextArea>s of their values
export function clearInput(input) {
  // If element is a hidden input, don't clear it. Causes problems with checkboxes, hide your inputs some other way.
  if (input.type === 'hidden') {

  } else if (input.type === 'checkbox') {
    // If element is a checkbox, uncheck but dont clear value, checkbox values have special meaning
    input.checked = false;
  } else if (input.type === 'radio' && (input.value === 'true' || input.value === 'false')) {
    // If element is a boolean radio pair, uncheck the true value, check the false value
    if (input.value === 'true') {
      input.checked = false;
    } else if (input.value === 'false') {
      input.checked = true;
    }
  } else {
    // If element is a plain input, we are safe to clear the value
    input.value = '';
  }
}

// Special case for clearing a <Select> tag
export function clearSelect(selectElement) {
  for (let i = 0; i < selectElement.options.length; i++) {
    selectElement.options[i].selected = false;
  }
}

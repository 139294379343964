import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["modal"]

  open(event) {
    // the index is set if we have more than one modal to open and need to be specific
    const index = event.target.dataset.index || 0

    Array.from(this.modalTargets)[index].dispatchEvent(new CustomEvent("open", {}))
  }
}

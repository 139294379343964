import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['radioButtons', 'fields'];

  connect() {
    console.log('CONNECTED');
    this.toggleFields();
  }

  toggleFields() {
    let selected = this.radioButtonsTarget.querySelector('input:checked').value;
    console.log('selected', selected);
    this.fieldsTargets.forEach((el, _) => {
      if (el.dataset.style == selected) {
        el.classList.remove('hide');
        el.querySelectorAll('input').forEach(input=>input.removeAttribute('disabled'));
      } else {
        el.classList.add('hide');
        el.querySelectorAll('input').forEach(input=>input.setAttribute('disabled', true));
      }
    });
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dynamicControls'];

  handler = () => {
    this.showDynamicControls();
  };

  connect() {
    if (this.hasDynamicControlsTarget) {
      this.element.querySelectorAll('input, textarea, select').forEach(element => {
        element.addEventListener('change', this.handler);
        element.addEventListener('keyup', this.handler);
      });

      if (this.element.querySelector('.changed')) {
          this.showDynamicControls();
      };
    }
  }

  disconnect() {
    if (this.hasDynamicControlsTarget) {
      this.element.querySelectorAll('input').forEach(element => {
        element.removeEventListener('change', this.handler);
        element.removeEventListener('keyup', this.handler);
      });
    }
  }

  showDynamicControls() {
    if (this.hasDynamicControlsTarget) {
      this.dynamicControlsTarget.classList.remove('hide');
    }
  }

  reload(event) {
    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      let new_element = data.querySelector('[data-controller~="form"]');
      this.element.parentNode.replaceChild(new_element, this.element);
    }
    else {
      let newData = new DOMParser().parseFromString(data, 'text/html');
      let new_element = newData.querySelector('[data-controller~="form"]');
      console.log(newData)
      console.log(new_element)
      this.element.parentNode.replaceChild(new_element, this.element);
    }
  }

  refresh(){
    window.location.reload();
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['other', 'list', 'with'];

  connect() {
    this.toggleFormVisibility();
  }

  toggleFormVisibility() {
    if (this.withTarget.value === 'other') {
      this.otherTarget.classList.remove('hide');
    } else {
      this.otherTarget.classList.add('hide');
    }
  }

}

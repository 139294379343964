import { Controller } from 'stimulus';
import _get from 'lodash.get';
import _set from 'lodash.set';

export default class extends Controller {

  static targets = ['url'];

  state = '';

  connect() {
    let element = this.element;
    let dataset = element.dataset;
    let selector = dataset.selector;
    if (selector == null) {
      throw new Error('Could not find data attribute `selector` on Element');
    }
    this.selector = selector;

    let property = dataset.property;
    if (property == null) {
      throw new Error('Could not find data attribute `property` on Element');
    }
    this.property = property;

    // should we throw if the element cannot be found?
    this.required = (dataset.required != undefined);

    this.reset();
  }

  getElement() {
    let el = document.querySelector(this.selector);
    if (el === null) {
      if (this.required) {
        throw new Error(`Could not retrieve element for selector '${this.selector}'`);
      } else {
        console.log(`Warning: Could not retrieve element for selector '${this.selector}'`);
      }
    }
    return el;
  }

  restore() {
    _set(this.getElement(), this.property, this.state);
  }

  reset() {
    this.state = _get(this.getElement(), this.property);
  }

}

// this controller is for managing the "Assigned to this group" tab. when you
// select some guests and click "Assign to another group", a modal pops up that
// asks you where you want to move them to.

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["where", "whereButton", "selectedGroup" ,"confirm", "guestsCopy", "groupCopy", "warningCopy"]

  connect() {
    // track how many guests are selected
    this.updateGuestsSelected = this.updateGuestsSelected.bind(this)
    this.updateWhereButtonDisabled = this.updateWhereButtonDisabled.bind(this)
    this.updateCopy = this.updateCopy.bind(this)
    this.assignedGuests = this.element.querySelectorAll("[name='transfer[individual_guest_ids][]']")
    this.assignedGuests.forEach(guest => guest.addEventListener("change", this.updateGuestsSelected))
    this.guestsSelected = Array.from(this.assignedGuests).reduce((acc, x) => x.checked ? acc + 1 : acc, 0)
    this.updateWhereButtonDisabled()
    this.updateCopy()
    // We always want the warning copy to be shown
    this.warningCopyTarget.classList.remove("hide")
  }

  confirm() {
    this.whereTarget.dispatchEvent(new CustomEvent("kill", {}))
    this.confirmTarget.dispatchEvent(new CustomEvent("open", {}))
  }

  // we checked or unchecked a guest, update the count accordingly
  updateGuestsSelected(event) {
    if (event.target.checked) {
      this.guestsSelected += 1
    } else {
      this.guestsSelected -= 1
    }
    this.updateWhereButtonDisabled()
    this.updateCopy()
  }

  // if we have no guests selected, the button should be disabled
  // if we have at least one guest selected, the button should be enabled
  updateWhereButtonDisabled() {
    if (this.guestsSelected <= 0) {
      this.whereButtonTarget.setAttribute("disabled", "")
    } else {
      this.whereButtonTarget.removeAttribute("disabled", "")
    }
  }

  updateCopy() {
    this.guestsCopyTarget.textContent = this.guestsSelected == 1 ? "1 guest" : `${this.guestsSelected} guests`
    if (this.hasSelectedGroupTarget) {
      const groupName = this.selectedGroupTarget.options[this.selectedGroupTarget.selectedIndex].textContent
      this.groupCopyTarget.textContent = `${groupName} Group`
    }
  }

  showWhereModal(event) {
    if (event.target.hasAttribute("disabled", "")) {
      event.preventDefault()
      return
    }
    this.whereTarget.dispatchEvent(new CustomEvent("open", {}))
  }
}

import { Controller } from 'stimulus'

export default class extends Controller {
  switch() {
    window.scrollTo(0, 0)

    this.element.dispatchEvent(
      new CustomEvent('tabs:switch', {
        bubbles: true,
        detail: {
          index: this.element.dataset.index,
        },
      })
    )
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['hidee'];

  toggleVisibility(e) {
    e.preventDefault()
    if (this.hideeTarget.classList.contains('hide')) {
      this.hideeTarget.classList.remove('hide')
    }
    else {
      this.hideeTarget.classList.add('hide')
    }
  }
}



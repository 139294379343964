// this controller is for automatically setting the
// "yes we're attending / no we can't make it" value required for
// updating assignments

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  connect() {
    this.update()
  }

  update() {
    this.inputTarget.value = [...this
      .element
      .querySelectorAll("input[type='checkbox']")]
      .some(el => el.checked)
  }
}

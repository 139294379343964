import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['oneDateCheckbox', 'multipleDateCheckbox', 'noDateCheckbox', 'firstDateContainer', 'lastDateContainer', 'firstDateLabel'];

  connect() {
    this.lastDateContainerTarget.classList.add('hide');

    if (this.lastDateContainerTarget.querySelector('.error')) {
      this.multipleDateCheckboxTarget.click();
    }

    if (this.oneDateCheckboxTarget.checked) this.checkOneDate()
    if (this.multipleDateCheckboxTarget.checked) this.checkMultipleDate()
    if (this.noDateCheckboxTarget.checked) this.checkNoDate()

    this.choseDate = this.choseDate.bind(this)
    this.element.addEventListener("flatpickr:changed", this.choseDate)
  }

  disconnect() {
    this.element.removeEventListener("flatpickr:changed", this.choseDate)
  }

  checkOneDate(e) {
    if (e === undefined || e.target.checked) {
      this.multipleDateCheckboxTarget.checked = false
      this.noDateCheckboxTarget.checked = false
      this.firstDateContainerTarget.classList.remove('hide');
      this.lastDateContainerTarget.classList.add('hide');
      this.firstDateLabelTarget.innerHTML = 'Date';
    }
    else {
      this.firstDateContainerTarget.classList.add('hide');
      this.lastDateContainerTarget.classList.add('hide');
    }
  }

  checkMultipleDate(e) {
    if (e === undefined || e.target.checked) {
      this.oneDateCheckboxTarget.checked = false
      this.noDateCheckboxTarget.checked = false
      this.firstDateContainerTarget.classList.remove('hide');
      this.lastDateContainerTarget.classList.remove('hide');
      this.firstDateLabelTarget.innerHTML = 'First Date';
    }
    else {
      this.firstDateContainerTarget.classList.add('hide');
      this.lastDateContainerTarget.classList.add('hide');
    }
  }

  checkNoDate(e) {
    this.oneDateCheckboxTarget.checked = false
    this.multipleDateCheckboxTarget.checked = false
    this.firstDateContainerTarget.classList.add('hide');
    this.lastDateContainerTarget.classList.add('hide');
  }

  choseDate(e) {
    const { dateStr, instance, dateFormat } = e.detail
    const firstDateChanged = this.firstDateContainerTarget.contains(e.target)
    const multipleDatesEnabled = this.multipleDateCheckboxTarget.checked

    if (firstDateChanged && multipleDatesEnabled) {
      this
        .lastDateContainerTarget
        .querySelector("[data-controller='flatpickr']")
        ._flatpickr
        .setDate(dateStr, false, dateFormat)
    }
  }
}

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.kill = this.kill.bind(this)
    this.killIfEscapePressed = this.killIfEscapePressed.bind(this)
    this.killIfClickedOutside = this.killIfClickedOutside.bind(this)

    document.addEventListener("keydown", this.killIfEscapePressed)
    document.body.addEventListener("pointerdown", this.killIfClickedOutside)
    document.body.style.overflow = "hidden"
  }

  killIfClickedOutside(event) {
    if (event.target.closest(".modal") != null || event.target.closest(".swal2-container") != null) {
      return
    }

    this.kill()
  }

  killIfEscapePressed(event) {
    if (event.code != "Escape") {
      return
    }

    this.kill()
  }

  disconnect() {
    this.kill()
  }

  kill() {
    document.removeEventListener("keydown", this.killIfEscapePressed)
    document.body.removeEventListener("pointerdown", this.killIfClickedOutside)
    document.body.style.overflow = null
    this.element.innerHTML = ""
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = [
    'filter',
    'table',
  ];

  connect() {
    let element = this.element;
    let dataset = element.dataset;
    let endpoint = dataset.endpoint;
    if (endpoint == null) {
      throw new Error('Could not find data attribute `endpoint` on Element');
    }
    this.endpoint = endpoint;
    this.refresh();
  }

  async refresh() {
    this.showLoadingSpinner();
    let payload = new FormData();
    let csrfToken = document.querySelector('[name="csrf-token"]').getAttribute('content') || '';

    payload.append('authenticity_token', csrfToken);
    payload.append('filter', this.filterTarget.value);

    let results = await fetch(this.endpoint, {
      method: 'post',
      body: payload,
    }).catch(() => {
      this.showErrorMessage();
    });

    if (results === undefined) {
      return;
    }

    if (!results.ok) {
      this.showErrorMessage();
    }

    let fragment = document.createElement('body');
    fragment.innerHTML = await results.text();
    let content = fragment.querySelector('.guest-information');
    if (content == null) {
      return this.showErrorMessage();
    }

    this.tableTarget.innerHTML = '';
    this.tableTarget.appendChild(content);
  }

  showErrorMessage() {
    this.tableTarget.innerHTML = `
        <div class="grid-x grid-margin-x interactive">
          <div class="cell auto"> 
            <p class="text-warning">
              Something went wrong and the guest list for this event could not be loaded. 
              Try adjusting the filter above.
            </p>
          </div>
        </div>
        `;
  }

  showLoadingSpinner() {
    this.tableTarget.innerHTML = `
      <div class="grid-x grid-margin-x interactive">
        <div class="cell auto"> 
           <i class="far fa-spinner fa-spin"></i>
            Loading... Please wait
        </div>
      </div>
    `;
  }

}

import { Controller } from "stimulus";

// this controller exists for the menu tab. Basically there's no form to submit, 
// so we add a fake submit button that just takes you to the next tab (either questions or confirmation)
export default class extends Controller {
  continue() {
    let index = document.getElementById('rsvp-questions-tab').classList.contains('disabled') ? 4 : 3;

    console.log("fire event")
    // fire an event so other controllers may listen for tab changes
    this.element.dispatchEvent(new CustomEvent(
      "tabs:switch",
      {
        bubbles: true,
        detail: { index: index }
      }
    ))
    }
}

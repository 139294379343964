import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = [ 'selectedCount', 'notRequired', 'sendForm' ];

  connect(){
    this.sendButtonTarget = document.querySelector(this.element.dataset.sendButtonSelector)
    this.updateSelectedCount()
  }

  confirmSend(e){
    if(this.sendButtonTarget.dataset.confirmCustom !== undefined){
      e.preventDefault()
      Swal.fire({
        title: '<h1>Almost there...</h1>',
        html: "<p>This will send your " + this.sendButtonTarget.dataset.sendWhat + " email to " + this.pluralizedGuestCount() + "</p>",
        showCancelButton: true,
        confirmButtonText: 'Send now',
        cancelButtonText: 'Cancel',
        buttonsStyling: false,
        customClass: {
          container: 'overlay-background',
          popup: 'overlay-content',
          confirmButton: 'button',
          cancelButton: 'button outlined red',
        },
      }).then(result => {
        if(result.isConfirmed){
          // Remove this data attribute and re-submit without confirmation
          delete this.sendButtonTarget.dataset.confirmCustom
          Rails.enableElement(this.sendButtonTarget)
          this.sendFormTarget.submit()
        }else{
          Rails.enableElement(this.sendButtonTarget)
        }
      });

      return false;
    }
    return true;
  }


  toggleAll(e){
    this.allCheckBoxes().forEach((el) => { el.checked = e.target.checked })
    this.updateSelectedCount()
  }

  guestToggled(){
    this.updateSelectedCount()
  }

  updateSelectedCount() {
    if (this.hasSelectedCountTarget) {
      this.selectedCountTarget.innerHTML = this.pluralizedGuestCount() + ' selected';
    }
    if (this.hasSendButtonTarget) {
      this.sendButtonTarget.disabled = (this.selectedGuestCount() === 0);
    }
  }

  selectedGuestCount(){
    return this.checkedCheckBoxes().length
  }

  pluralizedGuestCount(){
    var num = this.selectedGuestCount()

    if(num == 1){
      return '1 guest'
    }
    else{
      return '' + num + ' guests'
    }
  }

  allCheckBoxes(){
    return this.element.querySelectorAll('input.to-send')
  }

  checkedCheckBoxes(){
    return this.element.querySelectorAll('input.to-send:checked')
  }

  showNotRequired(e){
    e.preventDefault()
    e.target.classList.add('hide')
    this.notRequiredTarget.classList.remove('hide')
  }
}

import { Controller } from 'stimulus';
import Choices from 'choices.js';
import debounce from 'lodash.debounce';
import { emitter } from '../../../lib/eventBus';
import Rails from '@rails/ujs';

export default class extends Controller {

  static targets = ['input', 'assignment', 'guests'];

  async connect() {
    let element = this.element;

    let dataset = element.dataset;
    this.multiple = element.multiple;
    let searchUrl = dataset.searchUrl;
    if (searchUrl == null) {
      throw new Error('Could not find data attribute `searchUrl` on Element');
    }
    this.searchUrl = searchUrl;

    let seats = dataset.seats;
    if (seats == null) {
      throw new Error('Could not find data attribute `seats` on Element');
    }
    this.seats = seats;

    let self = this;

    let choices = new Choices(this.inputTarget, {
      placeholder: true,
      noChoicesText: '',
      placeholderValue: 'Add guests to this table',
      searchPlaceholderValue: 'Search for a guest',
      itemSelectText: 'Press to add guest',
      duplicateItemsAllowed: false,
      position: 'bottom',
      removeItemButton: 'removeItemButton' in dataset ? dataset.removeItemButton : this.multiple,
    });
    this.choices = choices;

    emitter.on('table-chose-guest', (id) => {
      choices.setChoices(choices._currentState.choices.filter((el) => el.value !== id), 'value', 'label', true);
    });

    this.inputTarget.addEventListener('choice', async (event) => {
      this
        .assignmentTarget
        .value = event.detail.choice.value

      this
        .inputTarget
        .closest("form")
        .requestSubmit()
    });

    this.inputTarget.addEventListener('search', debounce(async (event) => {
        await this.search(event.detail.value);
      }, 250).bind(this),
    );

    if (this.guestsTarget.querySelectorAll('.guest').length >= seats) {
      this.choices.disable();
    } else {
      this.choices.enable();
      await this.search();
    }
  }

  async search(query = '') {
    Rails.ajax({
      type: "GET",
      url: this.element.dataset.searchUrl,
      data: new URLSearchParams({ q: query }),
      success: (response) => {
        const newChoices = response.map(record => ({
          id: record[0],
          name: record[1],
        }))

        this.choices.setChoices(newChoices, 'id', 'name', true)
      },
    })
  }

  enable() {
    this.choices.enable();
  }

  disable() {
    this.choices.disable();
  }

  getCsrfToken() {
    return document.querySelector('[name="csrf-token"]').getAttribute('content') || '';
  }

}

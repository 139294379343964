import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['datetbc', 'guestsNotified'];

  connect() {
    this.toggleDate(this.datetbcTarget.checked);
  }

  dateChange(){
    this.showGuestsNotified()
  }

  dateTbc(e) {
    let disabled = e.target.checked;
    this.toggleDate(disabled);
    this.showGuestsNotified()
  }

  toggleDate(tbc) {
    document.querySelectorAll('.wedding_date select').forEach(el => el.disabled = tbc);
    document.querySelector('.input.wedding_date').classList.toggle('hide', tbc);
  }

  showGuestsNotified(){
    if(this.element.dataset.guestsNotified == '1'){
      this.guestsNotifiedTarget.classList.remove('hide')
    }
  }
}

import ContentBlockDragController from "./content_block_drag_controller"

// the only difference between assignments and content blocks
// is how we find the display_order input
export default class extends ContentBlockDragController {
  connect() {
    super.connect()
    this.isPositionInput = /table\[table_assignments_attributes\]\[\d+\]\[display_order\]/
  }
}

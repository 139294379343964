import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {

  connect() {
    let element = this.element;

    let dataset = element.dataset;
    this.multiple = element.multiple;

    let choices = new Choices(this.element, {
      duplicateItemsAllowed: false,
      removeItemButton: 'removeItemButton' in dataset ? dataset.removeItemButton : this.multiple,
      position: 'bottom',
      callbackOnCreateTemplates: function () {
        return {
          item(
            classNames,
            data,
            removeItemButton,
          ) {
            let paletteCircles = `<div class="palette-colour-circle small-medium ${data.customProperties}"></div>`;

            const div = Object.assign(document.createElement('div'), {
              className: classNames.item,
              innerHTML: `<div class="palette-colour-circle-wrapper">${paletteCircles}</div>` + data.label,
            });
            Object.assign(div.dataset, {
              item: '',
              id: data.id,
              value: data.value,
              customProperties: data.customProperties,
            });
            if (data.active) {
              div.setAttribute('aria-selected', 'true');
            }
            if (data.disabled) {
              div.setAttribute('aria-disabled', 'true');
            }
            if (data.placeholder) {
              div.classList.add(classNames.placeholder);
            }
            div.classList.add(data.highlighted ? classNames.highlightedState : classNames.itemSelectable);
            if (removeItemButton) {
              if (data.disabled) {
                div.classList.remove(classNames.itemSelectable);
              }
              div.dataset.deletable = '';
              const REMOVE_ITEM_TEXT = 'Remove item';
              const removeButton = Object.assign(document.createElement('button'), {
                type: 'button',
                className: classNames.button,
                innerHTML: REMOVE_ITEM_TEXT,
              });
              removeButton.setAttribute(
                'aria-label',
                `${REMOVE_ITEM_TEXT}: '${value}'`,
              );
              removeButton.dataset.button = '';
              div.appendChild(removeButton);
            }
            return div;
          },
          choice(
            classNames,
            data,
            selectText,
          ) {

            let paletteCircles = `<div class="palette-colour-circle small-medium ${data.customProperties}"></div>`;

            const div = Object.assign(document.createElement('div'), {
              id: data.elementId,
              innerHTML: `<div class="palette-colour-circle-wrapper">${paletteCircles}</div>` + data.label,
              className: `${classNames.item} ${classNames.itemChoice}`,
            });
            if (data.selected) {
              div.classList.add(classNames.selectedState);
            }
            if (data.placeholder) {
              div.classList.add(classNames.placeholder);
            }
            div.setAttribute('role', data.groupId > 0 ? 'treeitem' : 'option');
            Object.assign(div.dataset, {
              choice: '',
              id: data.id,
              value: data.value,
              selectText,
            });
            if (data.disabled) {
              div.classList.add(classNames.itemDisabled);
              div.dataset.choiceDisabled = '';
              div.setAttribute('aria-disabled', 'true');
            } else {
              div.classList.add(classNames.itemSelectable);
              div.dataset.choiceSelectable = '';
            }
            return div;
          },
        };
      },
    });

  }
}
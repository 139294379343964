import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["encouragingMessage", "content"]

  isCompleted() {
    const dishes = [...this.element.querySelectorAll(".dish-options")]

    return dishes
      .filter(el => {
        const options = [...el.querySelectorAll("input[type='radio']")]
        return options.reduce((acc, x) => acc || x.checked, false)
      })
      .length == dishes.length
  }

  hideIfCompleted(e) {
    const wasCompleted = this.completed
    this.completed = this.isCompleted()
    this.encouragingMessageTarget.classList.toggle("hide", !this.completed)

    if (!wasCompleted && this.completed) {
      this.element.dispatchEvent(new CustomEvent("toggle:toggle", {}))
    }
  }

  connect() {
    this.isCompleted = this.isCompleted.bind(this)
    this.hideIfCompleted = this.hideIfCompleted.bind(this)

    this.completed = this.isCompleted()

    this.inputs = this.element.querySelectorAll("input")
    // this.inputs.forEach(el => el.addEventListener("change", this.hideIfCompleted))
  }

  disconnect() {
    this.inputs.forEach(el => el.removeEventListener("change", this.hideIfCompleted))
  }
}

import { Controller } from 'stimulus';
import debounce from 'lodash.debounce';
import { DirectUpload } from '@rails/activestorage';
import { fetch_retry } from '../../lib/fetch_retry';

export default class extends Controller {

  static targets = [
    'datetbc',
    'venuetbc',
    'mapImagePreview',
    'addressField',
    'countryField',
    'mapImageInput',
    'mapImageError',
    'mapImageWrapper',
    'mapImageLoading',
  ];
  debouncedAddressChanged = debounce(this.addressChanged, 1000).bind(this);

  connect() {
    let element = this.element;
    let dataset = element.dataset;
    let googleMapsApiKey = dataset.gmaps;
    if (googleMapsApiKey == null) {
      throw new Error('Could not find data attribute `gmaps` on Element');
    }
    this.googleMapsApiKey = googleMapsApiKey;

    let directUploadUrl = this.mapImageInputTarget.dataset.directUploadUrl;
    if (directUploadUrl == null) {
      throw new Error('Could not find data attribute `directUploadUrl` on mapImageInputTarget');
    }
    this.directUploadUrl = directUploadUrl;

    let mapImageFormName = this.mapImageInputTarget.dataset.formName;
    if (mapImageFormName == null) {
      throw new Error('Could not find data attribute `formName` on mapImageInputTarget');
    }
    this.mapImageFormName = mapImageFormName;

    this.toggleDate(this.datetbcTarget.checked);
    this.toggleVenue(this.venuetbcTarget.checked);
    this.addressFieldTargets.forEach(el => {
      el.addEventListener('change', this.debouncedAddressChanged);
    });
    this.countryFieldTarget.addEventListener('change', this.debouncedAddressChanged);
  }

  disconnect() {
    this.addressFieldTargets.forEach(el => {
      el.removeEventListener('change', this.debouncedAddressChanged);
    });
  }

  addressChanged(event) {
    this.mapImageWrapperTarget.classList.remove('hide');
    this.hideMapImageError();
    this.showMapImagePreview();
    this.getMapImage();
  }

  showMapImageLoadingSpiner() {
    this.mapImageLoadingTarget.classList.remove('hide');
    this.mapImagePreviewTarget.classList.add('hide');
  }

  hideMapImageLoadingSpiner() {
    this.mapImageLoadingTarget.classList.add('hide');
    this.mapImagePreviewTarget.classList.remove('hide');
  }

  async getMapImage() {
    this.showMapImageLoadingSpiner();
    let addressValues = this.addressFieldTargets.map((el) => el.value.trim()).filter((el) => !!el && el !== '');
    let countryName = this.countryFieldTarget.options[this.countryFieldTarget.selectedIndex].text;
    addressValues.push(countryName);
    let address = encodeURIComponent(addressValues.join(','));
    let apiKey = `&key=${this.googleMapsApiKey}`;
    let zoomLevel = '&zoom=16';
    let mapType = '&maptype=roadmap';
    let imageSize = '&size=1200x1200';
    let scale = '&scale=2';
    let styles = '&style=feature:poi|visibility:off';
    let markers = `&markers=size:mid|scale:4|label:Venue|color:red|${address}`;
    let center = `&center=${address}`
    let url = `https://maps.googleapis.com/maps/api/staticmap?${mapType}${zoomLevel}${imageSize}${markers}${center}${scale}${styles}${apiKey}`;

    try {
      let results = await fetch_retry(url, {}, 4);
      let blob = await results.blob();
      let imageData = URL.createObjectURL(blob);
      this.mapImagePreviewTarget.src = imageData;

      let file = new File([blob], 'GmapsStaticImage.png');
      this.uploadFile(file);
    } catch {
      this.showMapImageError('Could not generate a map preview for this location. Please check that the address is correct.');
      this.hideMapImageLoadingSpiner();
    }
  }

  changeDate(e) {
    e.preventDefault();

    document.querySelector('.wedding-date').classList.add('hide');
    document.querySelector('.other-date').classList.remove('hide');
  }

  dateTbc(e) {
    let disabled = e.target.checked;
    this.toggleDate(disabled);
  }

  venueTbc(e) {
    let disabled = e.target.checked;
    this.toggleVenue(disabled);
  }

  toggleDate(tbc) {
    document.querySelectorAll('.other-date select').forEach(el => el.disabled = tbc);
    document.querySelectorAll('.start-and-end-time select').forEach(el => el.disabled = tbc);

    // If TBC, cant set date or time, so hide
    document.querySelector('.input.event_date').classList.toggle('hide', tbc);
    document.querySelector('.start-and-end-time').classList.toggle('hide', tbc);
  }

  toggleVenue(tbc) {
    document.querySelectorAll('.venue-address input').forEach(el => el.disabled = tbc);
    document.querySelector('.venue-address').classList.toggle('hide', tbc);
  }

  uploadFile(file) {
    const upload = new DirectUpload(file, this.directUploadUrl, this);
    const hiddenInput = document.createElement('input');
    hiddenInput.setAttribute('type', 'hidden');
    hiddenInput.name = this.mapImageFormName; // Add an appropriately-named hidden input to the form with a value of blob.signed_id so that the blob ids will be transmitted in the normal upload flow
    this.element.insertAdjacentElement('beforeend', hiddenInput);
    let attempt = (attempt_number) => {
      upload.create((error, blob) => {
        if (error && attempt_number === 4) {
          // Handle the error
          this.showMapImageError('Could not generate a map preview for this location. Please check that the address is correct. Your address will still be saved, but a map thumbnail will not appear next to it when it is shown');
          hiddenInput.remove();
          this.hideMapImageLoadingSpiner();
        } else if (error) {
          attempt(attempt_number + 1);
        } else {
          hiddenInput.setAttribute('value', blob.signed_id);
        }
      });
    };
    attempt(1);
  }

  showMapImagePreview() {
    this.mapImagePreviewTarget.classList.remove('hide');
  }

  hideMapImagePreview() {
    this.mapImagePreviewTarget.classList.add('hide');
  }

  showMapImageError(message) {
    this.mapImageErrorTarget.classList.remove('hide');
    this.mapImageErrorTarget.innerText = 'Could not generate a map preview for this location. Please check that the address is correct. Your address will still be saved, but a map thumbnail will not appear next to it when it is shown';
    this.hideMapImagePreview();
  }

  hideMapImageError() {
    this.mapImageErrorTarget.classList.add('hide');
    this.mapImageErrorTarget.innerText = '';
  }

  // DirectUpload delegate
  uploadRequestDidProgress(event) {
    const progress = event.loaded / event.total * 100;
    if (progress) {
      if (progress === 100) {
        this.hideMapImageLoadingSpiner();
      }
    }
  }

  directUploadWillCreateBlobWithXHR(xhr) {
    this.directUploadXhr = xhr;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.directUploadXhr = xhr;
    xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['disabler', 'disablee'];

  connect() {
    if (this.hasDisablerTarget && this.disablerTarget.checked) {
      this.disableInputs();
    }
  }

  toggle() {
    if (this.hasDisablerTarget && this.disablerTarget.checked) {
      this.disableInputs();
    } else {
      this.enableInputs();
    }
  }

  disableInputs() {
    this.disableeTargets.forEach((el, _) => {
      let flatpickr_controller = this.application.getControllerForElementAndIdentifier(el, "flatpickr");

      if (flatpickr_controller) {
        el.closest('.date-wrapper').classList.add('disabled')
      } else {
        el.disabled = true;
        el.setAttribute("disabled", "disabled");
        el.dataset.previousValue = el.value;
        el.value = '';
      }
    });
  }

  enableInputs() {
    this.disableeTargets.forEach((el, _) => {
      let flatpickr_controller = this.application.getControllerForElementAndIdentifier(el, "flatpickr");

      if (flatpickr_controller) {
        el.closest('.date-wrapper').classList.remove('disabled')
      } else {
        el.value = el.dataset.previousValue;
        el.disabled = false;
        el.removeAttribute("disabled");
      }
    });
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  timeout = null;

  connect() {
    let element = this.element;
    let dataset = element.dataset;

    let property = dataset.property;
    if (property === null) {
      throw new Error('Could not find data attribute `property` on Element');
    }
    this.property = property;

    let duration = dataset.duration;
    if (duration == null) {
      throw new Error('Could not find data attribute `duration` on Element');
    }

    let value = dataset.value;
    if (value === null) {
      throw new Error('Could not find data attribute `value` on Element');
    }
    this.value = value;

    this.setState();
    this.timeout = setTimeout(Number.parseInt(this.duration), this.removeState.bind(this));
  }

  disconnect() {
    clearTimeout(this.timeout);
  }

  setState() {
    this.element[this.property] += ` ${this.value} `;
  }

  removeState() {
    this.element[this.property] = this.element[this.property].replace(this.value, '').replaceAll('  ', ' ').trim();
  }

}

export function applyTemporaryState(element, propertyString, value, duration) {
  element.dataset.controller = (element.dataset.controller || '' + ` temporary-state `).trim().replaceAll('  ', ' ');
  element.dataset.property = propertyString;
  element.dataset.duration = duration;
  element.dataset.value = value;
}

export function applyTemporaryClass(element, value, duration) {
  applyTemporaryState(element, 'className', value, duration);
}
import { Controller } from 'stimulus';

export default class extends Controller {

  async connect() {
    let element = this.element;
    let dataset = element.dataset;
    let url = dataset.url;
    if (url == null) {
      throw new Error('Could not find data attribute `url` on Element');
    }
    this.url = url;

    await this.loadForm();
  }

  async loadForm() {
    let response = await fetch(this.url);

    if (!response.ok) {
      this.element.innerHTML = 'Something went wrong.';
    }

    // load the form into the page
    this.element.innerHTML = await response.text();
  }

}
import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

export const handleConfirm = function (element) {
  if (!allowAction(this)) {
    Rails.stopEverything(element);
  }
};

const allowAction = (element) => {
  if (element.getAttribute('data-confirm-swal') === null) {
    return true;
  }

  showConfirmationDialog(element);
  return false;
};

// Display the confirmation dialog
const showConfirmationDialog = (element) => {
  const message = element.getAttribute('data-confirm-swal');
  const text = element.getAttribute('data-text');
  const confirmText = element.getAttribute('data-confirm-text');
  const cancelText = element.getAttribute('data-cancel-text');

  Swal.fire({
    title: message || 'Are you sure?',
    text: text || '',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: confirmText || 'Yes',
    cancelButtonText: cancelText || 'Cancel',
    buttonsStyling: false,
    customClass: {
    confirmButton: 'button',
    cancelButton: 'button outlined red',
    }
  }).then(result => confirmed(element, result));
};

const confirmed = (element, result) => {
  if (result.value) {
    // User clicked confirm button
    element.removeAttribute('data-confirm-swal');

    if ('confirmSwalEvent' in element.dataset) {
      // fire a custom event
      Rails.fire(element, 'swal_confirmed');
    } else {
      // fire the default event
      if (element.tagName === 'FORM') {
        element.submit();
      } else {
        element.click();
      }
    }
  }
};

export default handleConfirm;

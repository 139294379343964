import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {

  open(e) {
    let image = e.target
    const url = image.dataset.popup || image.src
    const html = '<img src="'+url+'" />'
    const popup_size = image.dataset.size

    Swal.fire({
      html: html,
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
      buttonsStyling: false,
      padding: 0,
      customClass: {
        container: 'overlay-background',
        popup: 'overlay-content image ' + popup_size
      }
    })
  }

}

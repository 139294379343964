import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {

  boundHandler = this.handler.bind(this);

  connect() {
    this.element.querySelectorAll('input, select, textarea').forEach(el => el.addEventListener('change', this.boundHandler));
  }

  disconnect() {
    this.element.querySelectorAll('input, select, textarea').forEach(el => el.removeEventListener('change', this.boundHandler));
  }

  handler(e) {
    // this.element.submit()
    // Moved to this to support remote forms and CSRF properly
    Rails.fire(this.element, 'submit');
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  boundMessageReceived = this.messageReceived.bind(this);

  connect() {
    window.addEventListener('message', this.boundMessageReceived);

    this.element.addEventListener('load', (e) => {
      document.querySelector('.iframe-loading').classList.add('hide')
      this.element.classList.remove('hide')

    })


  }

  disconnect() {
    window.removeEventListener('message', this.boundMessageReceived);
  }

  messageReceived(message) {
    const sidebarHeight = document.querySelector('.sidebar')?.offsetHeight;
    const dummyBrowserHeight = document.querySelector('.dummy-browser')?.offsetHeight;

    if (sidebarHeight == null || dummyBrowserHeight == null) {
      const defaultHeight = this.element.parentNode.parentNode.offsetHeight
      this.resize(defaultHeight)
      return
    }

    let data = message.data;
    // if (data.hasOwnProperty('name') && data.name === 'iframe-body' && data.hasOwnProperty('height')) {
    //   // this.resize(data.height);
    //   this.resize(sidebarHeight);
    // }

    this.resize(sidebarHeight - dummyBrowserHeight - 4);


  }

  resize(height) {
    this.element.style.height = `${height}px`;
  }

}

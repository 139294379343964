import { Controller } from "stimulus";

// this controller is intended to be used alongside the draggable_controller,
// it's separate so the other one can be used for non content-block things.
export default class extends Controller {
  connect() {
    this.isPositionInput = /website_version\[content_blocks_attributes\]\[\d+\]\[display_order\]/
    this.updatePositions = this.updatePositions.bind(this)
    this.element.addEventListener("dragend", this.updatePositions)
  }

  disconnect() {
    this.element.removeEventListener("dragend", this.updatePositions)
  }

  // reorder all of the positions
  updatePositions() {
    // display orders aren't scoped, so if we always reuse the same values we can't mess it up
    const positionInputs = [...this.element.querySelectorAll("input")].filter(element => {
      return this.isPositionInput.test(element.name)
    })
    const positions = positionInputs
      .map(element => element.value)
      .sort((a, b) => { return parseInt(a) - parseInt(b) })

    let changed = false
    positionInputs.forEach((element, index) => {
      if (element.value !== positions[index]) {
        changed = true
      }
      element.value = positions[index]
    })

    if (changed) {
      // it doesn't matter which input, so just fire from the first one
      this.element.querySelector("input").dispatchEvent(new Event("change"))
    }
  }
}

import YesNoHideController from './yes_no_hide_controller';

export default class extends YesNoHideController {

  static targets = ['hide', 'show', 'yesTarget', 'noTarget'];
  clearInputsOnHide = false;

  connect() {
    let self = this;

    this.clearInputsOnHide = this.element.dataset.clearInputsOnHide === 'true' || false;
    if (this.hasYesTargetTarget) {
      this.yesTarget = this.yesTargetTarget || null;
    } else {
      // console.log('Has no yesTarget:', this.element);
    }
    if (this.hasNoTargetTarget) {
      this.noTarget = this.noTargetTarget || null;
    } else {
      // console.log('Has no noTarget:', this.element)
    }
    super.connect();
  }

}

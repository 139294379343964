import { Controller } from 'stimulus';

export default class extends Controller {

  edit(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.showEditForm();
  }

  add(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.showAddForm();
  }

  cancelEditing(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.hideEditForm();
  }

  cancelAdding(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.hideAddForm();
  }

  showEditForm() {
    this.element.querySelector('td.guest-names').classList.add('hide');
    this.element.querySelector('td.guest-email').classList.add('hide');
    this.element.querySelector('td.edit-button').classList.add('hide');
    this.element.querySelector('td.edit-form').classList.remove('hide');
  }

  showAddForm() {
    this.element.querySelector('td.add-button').classList.add('hide');
    this.element.querySelector('td.add-form').classList.remove('hide');
  }

  hideEditForm() {
    this.element.querySelector('td.guest-names').classList.remove('hide');
    this.element.querySelector('td.guest-email').classList.remove('hide');
    this.element.querySelector('td.edit-button').classList.remove('hide');
    this.element.querySelector('td.edit-form').classList.add('hide');
  }

  hideAddForm() {
    this.element.querySelector('td.add-button').classList.remove('hide');
    this.element.querySelector('td.add-form').classList.add('hide');
  }

  formSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      if (data.querySelector('.new-guest-group-row')) {

        if (this.element.classList.contains('new')) {
          this.element.insertAdjacentHTML('afterend', data.querySelector('.new-guest-group-row').outerHTML);
          this.element.parentElement.parentElement.querySelector('thead').classList.remove('hide');
        } else {
          this.element.insertAdjacentHTML('afterend', data.querySelector('.new-guest-group-row').outerHTML);
          this.element.querySelector('td.add-form').classList.add('hide');
          this.element.querySelector('td.add-button').classList.remove('hide');
        }
      }
      let updated_row = data.querySelector('[data-controller="guest-list-row"]');
      this.element.parentNode.replaceChild(updated_row, this.element);

      document.querySelectorAll('.show-after-first-guest').forEach((el) => {el.classList.remove('hide')})
    } else {
      console.error('Unknown', data);
    }
  }

  toggleDetails(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    let id = this.element.dataset.guestGroupId;
    let detailsRow = document.querySelector('.guest-group-details-row[data-guest-group-id="' + id + '"]');
    detailsRow.classList.toggle('hide');
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'inputs'];

  connect() {
    this.disableEnable()
  }

  disableEnable() {
    if (this.selectTarget.checked == true) {
      this.inputsTarget.classList.remove('disabled');
    }
    else {
      this.inputsTarget.classList.add('disabled');
    }
  }
}

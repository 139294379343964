import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['field', 'submit'];

  connect() {
  }

  confirm(e) {
    e.preventDefault();
    this.fieldTarget.value = 'confirmed';
    this.submitTarget.click();
  }

  defer(e) {
    e.preventDefault();
    this.fieldTarget.value = 'unconfirmed';
    this.submitTarget.click();
  }
}

import { Controller } from "stimulus";
import Glide, {
  Breakpoints,
  Controls,
} from "@glidejs/glide/dist/glide.modular.esm";

export default class extends Controller {
  glide = null;

  connect() {
    let self = this;

    let perView = this.element.dataset.perview || 1;
    let gap = this.element.dataset.gap || 0;
    let type = this.element.dataset.type || "slider";

    this.glide = new Glide(this.element, {
      focusAt: "center",
      autoplay: true,
      peek: 0,
      perView: perView,
      gap: gap,
      type: type,
      breakpoints: {
        640: {
          perView: perView,
          gap: gap,
          gap: 0,
          perView: 1,
        },
      },
    }).mount({
      Controls,
      Breakpoints,
    });

    // force Glide to recalculate image widths properly
    // fixes active slides being slightly too narrow
    document.querySelector("body").style.width = "99vw";
    document.querySelector("body").style.width = "100vw";

    // Add events to tabs/menu buttons to update the carousels
    // when clicked, as otherwise image widths are set to 0px
    var tabs = document.querySelectorAll(".website-tab");
    for (var i = 0; i < tabs.length; i++) {
      tabs[i].addEventListener(
        "click",
        this.updateGlide.bind(event, this.glide)
      );
    }
  }

  updateGlide(glide) {
    // Timeout set so update is made after dom elements
    // are made visible
    setTimeout(() => {
      glide.update();
    }, 50);
    document.querySelector("body").style.width = "99vw";
    document.querySelector("body").style.width = "100vw";
  }
}

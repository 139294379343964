import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["flip", "flop"]

  connect() {
    this.toggle = this.toggle.bind(this)
    this.element.addEventListener("toggle:toggle", this.toggle)
    this.toggle()
  }

  disconnect() {
    this.element.removeEventListener("toggle:toggle", this.toggle)
  }

  toggle(e) {
    // alert("toggle")
    if (e != null) e.preventDefault()

    if ( this.element.dataset.state === "flip" ) {
      this.element.dataset.state = "flop"

      this.flipTargets.forEach(element => element.style.setProperty("display", "none"))
      this.flopTargets.forEach(element => element.style.removeProperty("display"))
    } else {
      this.element.dataset.state = "flip"
      this.flopTargets.forEach(element => element.style.setProperty("display", "none"))
      this.flipTargets.forEach(element => element.style.removeProperty("display"))
    }
  }
}

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'core-js';
import smoothscroll from 'smoothscroll-polyfill';

// html5 drag events for touchscreens
import { polyfill } from "mobile-drag-drop";
import { scrollBehaviourDragImageTranslateOverride } from "mobile-drag-drop/scroll-behaviour";

import 'regenerator-runtime/runtime';
import 'javascripts/controllers';
import 'flatpickr/dist/flatpickr.css';
import 'stylesheets/application.scss';
import 'whatwg-fetch';
import { default as apply_element_closest_polyfill } from 'element-closest';
import Rails from '@rails/ujs';
import handleSweetAlertConfirm from '../lib/sweet_alerts';
import Trix from "trix/dist/trix.esm"

if (!window._rails_loaded) {
  Rails.delegate(document, 'a[data-confirm-swal]', 'click', handleSweetAlertConfirm);
  Rails.delegate(document, 'button[data-confirm-swal]:not([form])', 'click', handleSweetAlertConfirm);
  Rails.delegate(document, 'form[data-confirm-swal]', 'submit', handleSweetAlertConfirm);
  Rails.start();
}
require('@rails/actiontext');
require('@rails/activestorage').start();
require('../lib/action_text_overrides');
require('../lib/polyfills/element-remove');
require('turbolinks').start();

apply_element_closest_polyfill(window); // Run polyfill setup
smoothscroll.polyfill(); // Run polyfill setup
polyfill({
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
  tryFindDraggableTarget: (event) => {
    // make sure the thing we touched is intended to be draggable
    // before we allow
    return event.target.closest("[draggable]")
  },
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);


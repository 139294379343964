import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {
    this.filter();
  }

  sort(e) {
    let order = e.target.value;

    // Anonymous function to get the right sort value for a product
    let getSortVal = (node) => {
      let item = node.querySelector('.hotel-result');
      return order === '' ? parseInt(item.dataset.sortDistance) : parseFloat(item.dataset.price);
    };

    // Set the sort order
    let gt = (order === 'desc' ? -1 : 1);
    let lt = (order === 'desc' ? 1 : -1);

    // Find the grid (which should be the .grid-x)
    let list = document.querySelector('#hotel-grid');

    // Loop over the .cell children
    // Sort by data attributes within
    // Reorder
    [...list.children]
    .sort((a, b) => getSortVal(a) > getSortVal(b) ? gt : lt)
    .forEach(node => list.appendChild(node));
  }

  filter() {
    let priceFilter = document.querySelector('input[name="price_range"]:checked').value;
    let ratingFilter = [];
    for (let star of document.querySelectorAll('input[name="star_rating"]:checked')) {
      ratingFilter.push(star.value);
    }
    let hideUnrecommended = document.querySelector('input[name="recommended"]').checked;

    let shouldBeVisible = (node) => {
      let item = node.querySelector('.hotel-result');
      let visible = true;

      // For the recommended filter to work there must be an element with the recommended
      // class within the card
      if (visible && hideUnrecommended && item.querySelectorAll('.recommended').length === 0) {
        visible = false;
      }

      if (visible && priceFilter !== '') {
        let price = parseFloat(item.dataset.price);
        let min, max;
        [min, max] = priceFilter.split('-').map(p => parseInt(p));

        if (price < min || price > max) {
          visible = false;
        }
      }

      if (visible && ratingFilter.length > 0 && !ratingFilter.includes(item.dataset.starRating)) {
        visible = false;
      }

      return visible;
    };

    // Find the grid (which should be the .grid-x)
    let list = document.querySelector('#hotel-grid');

    [...list.children].forEach(node => shouldBeVisible(node) ? node.classList.remove('hide') : node.classList.add('hide'));
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu'];

  bindOpen = () => {
    this.toggle();
  };

  connect() {
    let opener = this.opener();
    opener?.addEventListener('click', this.bindOpen);
    opener?.classList?.remove('active');
  }

  disconnect() {
    let opener = this.opener();
    opener?.removeEventListener('click', this.bindOpen);
    opener?.classList?.add('active');
  }

  opener() {
    return document.querySelector('.mobile-toggle');
  }

  toggle(event = null) {
    if (event !== null) {
      event.preventDefault();
    }

    if (this.element.classList.contains('is-open')) {
      this.element.classList.remove('is-open');
      this.opener()?.classList?.remove('active');

    } else {
      this.element.classList.add('is-open');
      this.opener()?.classList?.add('active');
    }
  }

  close(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.element.classList.remove('is-open');
    this.opener()?.classList?.remove('active');
  }
}
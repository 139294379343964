import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['filteredGuests'];

  toggleAll(e) {
    this.allCheckBoxes().forEach((el) => { el.checked = e.target.checked });
  }

  allCheckBoxes(){
    return this.element.querySelectorAll('.guest-event-assignments input[type="checkbox"]');
  }

  showFilteredGuests(e){
    e.preventDefault()
    e.target.remove()
    this.filteredGuestsTarget.classList.remove('hide')
    return false;
  }
}

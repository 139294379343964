import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {

  initIntialSelection = false;

  connect() {
    if (this.element.choices != null) {
      return
    }

    let element = this.element;

    let dataset = element.dataset;
    this.multiple = element.multiple;
    this.searchEnabled == dataset.searchDisabled ? false : true
    this.itemSelectText = dataset.itemSelectText || ""
    let self = this;

    let swatchSize = dataset.swatchSize;
    if (swatchSize == null) {
      swatchSize = 'small'
    }

    // this is an ugly hack, but no time to rewrite this controller.
    // we need save the website for every 'choice' event, but choice
    // event is fired as part of setup. To avoid infinite loop, we
    // need to differentiate.
    //
    // we also need to have a "reset fonts" flag for when the user hasn't changed
    // the recommended selection, but has picked an individual font. hopefully this
    // can be rewritten
    const setFontsFlag = element.closest('form').querySelector('#fonts_setting_font_pair')
    if (setFontsFlag != null) {
      setFontsFlag.value = false

      this.element.addEventListener('choice', (event) => {
        if (!event.detail.hasOwnProperty('initial')) {
          setFontsFlag.value = true
          this.element.dispatchEvent(new Event("change"))
        }
      })
    }

    this.element.choices = new Choices(this.element, {
      duplicateItemsAllowed: false,
      searchEnabled: this.searchDisabled,
      itemSelectText: this.itemSelectText,
      position: 'bottom',
      removeItemButton: 'removeItemButton' in dataset ? dataset.removeItemButton : this.multiple,
      callbackOnCreateTemplates: function () {
        return {
          item(
            classNames,
            data,
            removeItemButton,
          ) {
            // Choices doesnt fire an initial choice event when preselected. Do it for them.
            // If this is the first paint, we're currently drawing 'selected' things, fire a 'choice' event for the first thing we paint.
            if (!self.initIntialSelection) {
              self.initIntialSelection = true;
              // create and dispatch the event
              let event = new CustomEvent('choice', {
                detail: {
                  choice: data,
                  initial: "true",
                },
              });
              self.element.dispatchEvent(event);
            }

            let fontPreviews = '';
            if (data.customProperties) {
              try {
                let json = JSON.parse(data.customProperties);
                fontPreviews = json.map((font) => `<style> @import url('${font.url}'); </style><div class="fontPreview" style="font-family: ${font.font_family};">${font.name}</div>`).join('');
              } catch (e) {
                // ignore
              }
            }
            else {
              fontPreviews = '<div class="fontPreview">Theme Default</div>'
            }

            const div = Object.assign(document.createElement('div'), {
              className: classNames.item,
              innerHTML: data.label + `<div class="font-preview-wrapper">${fontPreviews}</div>`,
            });
            Object.assign(div.dataset, {
              item: '',
              id: data.id,
              value: data.value,
              customProperties: data.customProperties,
            });
            if (data.active) {
              div.setAttribute('aria-selected', 'true');
            }
            if (data.disabled) {
              div.setAttribute('aria-disabled', 'true');
            }
            if (data.placeholder) {
              div.classList.add(classNames.placeholder);
            }
            div.classList.add(data.highlighted ? classNames.highlightedState : classNames.itemSelectable);
            if (removeItemButton) {
              if (data.disabled) {
                div.classList.remove(classNames.itemSelectable);
              }
              div.dataset.deletable = '';
              const REMOVE_ITEM_TEXT = 'Remove item';
              const removeButton = Object.assign(document.createElement('button'), {
                type: 'button',
                className: classNames.button,
                innerHTML: REMOVE_ITEM_TEXT,
              });
              removeButton.setAttribute(
                'aria-label',
                `${REMOVE_ITEM_TEXT}: '${value}'`,
              );
              removeButton.dataset.button = '';
              div.appendChild(removeButton);
            }
            return div;
          },
          choice(
            classNames,
            data,
            selectText,
          ) {

            let fontPreviews = '';
            if (data.customProperties) {
              try {
                let json = JSON.parse(data.customProperties);
                fontPreviews = json.map((font) => `<style> @import url('${font.url}'); </style><div class="fontPreview" style="font-family: ${font.font_family};">${font.name}</div>`).join('');
              } catch (e) {
                // ignore
              }
            }
            else {
              fontPreviews = '<div class="fontPreview">Theme Default</div>'
            }

            const div = Object.assign(document.createElement('div'), {
              id: data.elementId,
              innerHTML: data.label + `<div class="font-preview-wrapper">${fontPreviews}</div>`,
              className: `${classNames.item} ${classNames.itemChoice}`,
            });
            if (data.selected) {
              div.classList.add(classNames.selectedState);
            }
            if (data.placeholder) {
              div.classList.add(classNames.placeholder);
            }
            div.setAttribute('role', data.groupId > 0 ? 'treeitem' : 'option');
            Object.assign(div.dataset, {
              choice: '',
              id: data.id,
              value: data.value,
              selectText,
            });
            if (data.disabled) {
              div.classList.add(classNames.itemDisabled);
              div.dataset.choiceDisabled = '';
              div.setAttribute('aria-disabled', 'true');
            } else {
              div.classList.add(classNames.itemSelectable);
              div.dataset.choiceSelectable = '';
            }
            return div;
          },
        };
      },
    });

  }
}

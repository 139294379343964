import Trix from "trix/dist/trix.esm"

// formerly small, now default
Trix.config.blockAttributes.default.tagName = "div"

// formerly large, now heading
Trix.config.blockAttributes.heading1= {
  tagName: 'h1',
  terminal: true,
  breakOnReturn: true,
  group: false,
  exclusive: true,
}

// formerly medium, now small heading
Trix.config.blockAttributes.heading2 = {
  tagName: 'h2',
  terminal: true,
  breakOnReturn: true,
  group: false,
  exclusive: true,
}

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3',
  terminal: true,
  breakOnReturn: true,
  group: false,
  exclusive: true,
}

addEventListener("trix-initialize", (event) => {
  const buttons = event.target.toolbarElement.querySelector(".trix-button-row")
  event.target.parentElement.setAttribute("data-controller", "trix-controls")

  // disable the buttons and button groups we don't want the user to see
  buttons
    .querySelectorAll(`
      .trix-button-group--file-tools,
      .trix-button--icon-link,
      .trix-button--icon-strike,
      .trix-button--icon-heading-1,
      .trix-button--icon-increase-nesting-level,
      .trix-button--icon-decrease-nesting-level,
      .trix-button--icon-quote,
      .trix-button--icon-bullet-list,
      .trix-button--icon-number-list,
      .trix-button--icon-code
    `)
    .forEach(element => element.remove())

  // add some block buttons
  const button = (name, label) => `
    <button class="trix-button" data-trix-attribute="${name}">
      ${label}
    </button>
  `

  const newBlockButtons = [
    button ("heading3", "Paragraph"),
    button("heading2", "Heading 2"),
    button("heading1", "Heading 1"),
  ]

  const blockGroup = buttons.querySelector(".trix-button-group--block-tools")
  newBlockButtons.forEach(markup => blockGroup.insertAdjacentHTML("afterbegin", markup))
})


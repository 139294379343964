import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['firstName', 'lastName', 'unknownName'];

  connect() {
    this.checkBlankName()
  }

  nameChanged(){
    this.checkBlankName()
  }

  checkBlankName(){
    if(!this.hasUnknownNameTarget){
      return
    }

    if(this.firstNameTarget.value.length == 0 && this.lastNameTarget.value.length == 0){
      this.unknownNameTarget.classList.remove('hide')
    }
    else{
      this.unknownNameTarget.classList.add('hide')
    }
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    let self = this;

    let target = this.element;
    target.style.resize = 'none';
    target.style.boxSizing = 'border-box';

    let handler = () => {
      self.autosize(target);
    };

    handler()
    window.addEventListener('resize', handler);
    target.addEventListener('input', handler);
    target.addEventListener('focus', handler);
  }

  autosize(element) {
    let offset = element.offsetHeight - element.clientHeight;
    element.style.height = 'auto';
    element.style.height = element.scrollHeight + offset + 'px';
  }

}

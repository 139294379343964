import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  reloadEventAssignments(event) {
    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      let new_element = data.querySelector('[id^="assigned_events_form"]');
      let current_element = document.querySelector('[id^="assigned_events_form"]');
      current_element.parentNode.replaceChild(new_element, current_element);
    } else {
      console.error('Unknown', data);
    }
  }

  reload(event) {
    window.location.search = ''
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['message', 'messageLimit'];

  maxChars = 500;
  maxLines = 7;

  connect() {
    this.refreshBasketTotals();
    this.updateMessageLimit();
  }

  change() {
    this.refreshBasketTotals();
  }

  refreshBasketTotals() {
    fetch(this.element.dataset.endpoint)
    .then(response => response.json())
    .then(response => {
      this.element.querySelector('.total-to-pay').innerHTML = response.to_pay;
      this.element.querySelector('.extras-total').innerHTML = response.upsell_total;
    });
  }

  enforceMessageLimit(e) {
    let metrics = this.textMetrics();

    let tooManyChars = metrics.numChars >= this.maxChars && e.key.length === 1 && !e.ctrlKey;
    let tooManyLines = metrics.numLines >= this.maxLines && e.key === 'Enter';

    if (tooManyChars || tooManyLines) {
      e.preventDefault();
    }
  }

  updateMessageLimit() {
    let metrics = this.textMetrics();
    let charsRemaining = this.maxChars - metrics.numChars;
    let linesRemaining = this.maxLines - metrics.numLines;
    this.messageLimitTarget.innerHTML = 'Remaining: ' + charsRemaining + ' characters / ' + linesRemaining + ' lines.';

    // Maybe somebody pasted something in, but we need to trim it
    if (charsRemaining < 0 || linesRemaining < 0) {
      this.trimText();
    }
  }

  textMetrics() {
    let text = this.messageTarget.value;
    let metrics = {numChars: 0, numLines: 0};
    if (!text.length) {
      return metrics;
    }

    let lines = text.split(/\r\n|\n|\r/gm);
    for (let i in lines) {
      metrics.numChars += lines[i].trim().length;
      metrics.numLines += 1;
    }

    return metrics;
  };

  filterInput() {
    // Prevent emojis
    this.messageTarget.value = this.messageTarget.value.replace(/[\u0100-\uffff]/g, '');
  }

  trimText() {
    let text = this.messageTarget.value.substr(0, 500);
    let lines = text.split(/\r\n|\n|\r/gm);
    text = lines.slice(0, 7).join('\r\n');
    this.messageTarget.value = text;

    this.updateMessageLimit()
  }
}

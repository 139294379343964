import { Controller } from 'stimulus';
import tippy, { sticky } from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

export default class extends Controller {

  tippyEl = null;

  connect() {
    this.tippyEl = tippy(this.element, {
      trigger: 'mouseenter focus click',
      interactiveBorder: 10,
      content: this.element.dataset.content || this.element.title,
      allowHTML: true,
      interactive: true,
      sticky: true,
      plugins: [sticky],
      placement: this.element.dataset.placement || 'top',
    });
  }

  disconnect() {
    this.tippyEl.destroy();
  }

}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['fields'];

  toggle(e) {
    e.preventDefault()
    this.fieldsTarget.classList.toggle('hide');
  }
}


// controller for swapping between some tabs of content. Will be obsolete when we
// upgrade to Turbo.

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.selectTabEvent = this.selectTabEvent.bind(this)

    this.tabs = this.tabs()
    this.tabs.forEach(([header, _content, handler]) => header.addEventListener("pointerdown", handler))
    this.selectTab(this.element.dataset.index)

    this.element.addEventListener("tabs:switch", this.selectTabEvent)
  }

  disconnect() {
    this.tabs.forEach(([header, _content, handler]) => header.removeEventListener("pointerdown", handler))
    this.element.removeEventListener("tabs:switch", this.selectTabEvent)
  }

  tabs() {
    // have to be direct descendents
    const headers = this.element.querySelectorAll(":scope > [data-tabs-headers] > [data-tabs-header]")
    const contents = this.element.querySelectorAll(":scope > [data-tabs-content]")

    return Array.from(headers).map((header, index) => {
      return [header, contents[index], (event) => this.selectTab(index)]
    })
  }

  // this is just like selectTab, but in response to a custom event
  selectTabEvent(event) {
    // if we have a situation with nested tabs, we only want to
    // switch the closest, so stop bubbling
    event.stopPropagation()

    this.selectTab(event.detail.index)
  }

  selectTab(index) {
    this.element.dataset.index = index

    this.tabs.forEach((tab, tabIndex) => {
      if (tabIndex == index) {
        this.tabs[index][0].classList.add("selected")
        this.tabs[index][1].classList.remove("hide")
        return
      }

      tab[0].classList.remove("selected")
      tab[1].classList.add("hide")
    })

    // fire an event so other controllers may listen for tab changes
    this.element.dispatchEvent(new CustomEvent(
      "tabs:switched",
      {
        bubbles: true,
        detail: { index: index }
      }
    ))
  }

}

// Generic controller for cropping an image before upload. When an HTML image
// field changes, it shows a modal that allows you to crop.

import { Controller } from 'stimulus'
import Swal from 'sweetalert2'
import Croppie from 'croppie'

export default class extends Controller {

  crop(e) {
    if (e.target.files.length == 1) {
      const fileInput = e.target
      const file = e.target.files[0]
      const reader = new FileReader()

      const showModal = () => {
        const finishCropping = (blob) => {
          const croppedFile = new File([blob], file.name)
          const dataTransfer = new DataTransfer()
          dataTransfer.items.add(croppedFile)
          fileInput.files = dataTransfer.files

          // this is how we communicate with the file upload stimulus controller,
          // to update the preview image
          fileInput.dispatchEvent(
            new CustomEvent(
              "fileupload:changed",
              {
                bubbles: true
              }
            )
          )
        }

        const startCropping = (modal) => {
          const croppieEl = modal.querySelector("[data-crop]")
          const croppie = new Croppie(croppieEl, {
            viewport: {
                width: 290,
                height: 290
            },
            boundary:{
                width:  '100%',
                height: 300
            },
            enforceBoundary: true,
            enableExif: false,
          })

          croppie.bind({ url: reader.result }).then(() => {
            croppie.setZoom(0)
          })

          const finishButton = modal.querySelector(".swal2-confirm")
          finishButton.addEventListener("click", () => {
            croppie.result({ type: 'blob', size: { width: 1000, height: 1000 }, format: 'jpeg'}).then(finishCropping)
          })
        }

        Swal.fire({
          html: `<div data-crop></div>`,
          // this will change to didOpen if we ever update sweet alerts
          onOpen: startCropping,
          buttonsStyling: false,
          showClass: { popup: '' }, // Any opening animation will break croppy
          customClass: {
            container: 'overlay-background',
            popup: 'overlay-content',
            confirmButton: 'button',
            cancelButton: 'button outlined red'
          }
        })

        reader.removeEventListener("load", showModal)
      }

      reader.addEventListener("load", showModal)
      reader.readAsDataURL(file)
    }
  }
}


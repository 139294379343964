import { Controller } from 'stimulus';
import Swal from 'sweetalert2';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['iframe'];

  boundChangeHandler = this.changeHandler.bind(this);

  connect() {
    this.element.querySelectorAll('input, select, textarea').forEach(el => el.addEventListener('change', this.boundChangeHandler));
  }

  disconnect() {
    this.element.querySelectorAll('input, select, textarea').forEach(el => el.removeEventListener('change', this.boundChangeHandler));
  }

  changeHandler(e) {
    this.element.setAttribute('dirty', 'true');
    setTimeout(() => { Rails.fire(this.element, 'submit') }, 300);
  }

  leaveHandler(event) {
    if (this.element.hasAttribute('dirty')) {
      event.preventDefault();
      Swal.fire({
        title: '<h1>You have unsaved changes</h1>',
        html: "<p>Are you sure you wish to leave this page? Your unsaved changes will be lost.</p>",
        showCancelButton: true,
        confirmButtonText: 'Leave and discard changes',
        cancelButtonText: 'Stay',
        buttonsStyling: false,
        customClass: {
          container: 'overlay-background',
          popup: 'overlay-content',
          confirmButton: 'button',
          cancelButton: 'button outlined red',
        },
      }).then(result => {
        if (result.isConfirmed) {
          window.location = event.data.url;
        } else {
          event.preventDefault();
        };
      });
    }
  }

  reload(event) {
    const [data, status, xhr] = event.detail;
    if (data instanceof Node) {
      let new_element = data.querySelector('[data-controller~="form"]');
      this.element.parentNode.replaceChild(new_element, this.element);
    } else {
      this.iframeTarget.innerHTML = this.iframeTarget.innerHTML;
    }
  }

  refresh(){
    window.location.reload();
  }
}

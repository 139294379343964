import { Controller } from "stimulus";
import Rails from '@rails/ujs';

// this is like a turbo frame, but we don't have turbo
export default class extends Controller {
  connect() {
    this.load()

    // if we detect the src attribute changing, reload
    const config = { attributes: true }
    this.observer = new MutationObserver((mutationList, observer) => {
      for (const mutation of mutationList) {
        if (mutation.attributeName == 'data-src') this.load()
      }
    });

    this.observer.observe(this.element, config);
  }

  disconnect() {
    this.observer.disconnect()
  }

  load() {
    Rails.ajax({
      type: "GET",
      url: this.element.dataset.src
    })
  }


}

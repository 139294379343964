// this is for toggling content elsewhere on the page depending on the currently selected tab
// warning: this will behave weirdly with nested tabs

import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.selector = this.element.dataset.selector
    this.remotes = this.element.querySelectorAll(`:scope ${this.selector}`)
    this.changeRemote = this.changeRemote.bind(this)
    this.element.addEventListener("tabs:switched", this.changeRemote)
  }

  disconnect() {
    this.element.removeEventListener("tabs:switched", this.changeRemote)
  }

  changeRemote(event) {
    const index = event.detail.index
    this.remotes.forEach((title, i) => {
      if (i == index) {
        title.classList.remove("hide")
      } else {
        title.classList.add("hide")
      }
    })
  }
}

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu'];

  bindOpen = () => {
    this.open();
  };

  connect() {
    let opener = this.opener();
    opener?.addEventListener('click', this.bindOpen);
    opener?.classList?.remove('hide');
  }

  disconnect() {
    let opener = this.opener();
    opener?.removeEventListener('click', this.bindOpen);
    opener?.classList?.add('hide');
  }

  opener() {
    return document.querySelector('.off-canvas-opener');
  }

  open(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.element.classList.add('is-open');
    this.opener()?.classList?.add('hide');

  }

  close(event = null) {
    if (event !== null) {
      event.preventDefault();
    }
    this.element.classList.remove('is-open');
    this.opener()?.classList?.remove('hide');
  }
}
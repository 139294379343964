import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"]

  select(event) {
    // update the form field
    this.inputTarget.value =
      event
        .target
        .closest(".tab")
        .dataset
        .value

    // visually select the right tab
    this.element
      .querySelectorAll('[data-action="click->tabbed-select#select"]')
      .forEach(e => {
        e.classList.toggle(
          "selected",
          e.dataset.value == this.inputTarget.value
        )
      })

    // fire an event in case we have any other controllers
    // waiting for the element to change
    this.inputTarget.dispatchEvent(new Event("change"))
  }
}

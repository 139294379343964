// this controller is for handling logic on the group details page for an rsvp group

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["event", "rsvpSettings", "openRsvp", "allEvents"]

  connect() {
    this.toggleRsvpSettings()
  }

  toggleRsvpSettings() {
    if (!this.hasRsvpSettingsTarget) {
      return;
    }

    const areSomeEventsChecked = this.eventTargets.some(target => target.checked);
    const isAllEventsGroup = this.hasAllEventsTarget

    if (areSomeEventsChecked || isAllEventsGroup) {
      this.rsvpSettingsTarget.style.removeProperty("display");
    } else {
      if (this.openRsvpTarget.checked == true) {
        this.openRsvpTarget.click()
      }
      this.rsvpSettingsTarget.style.setProperty("display", "none");
    }
  }
}

import { Controller } from 'stimulus';
import Choices from 'choices.js';

export default class extends Controller {
  static targets = ['fonts'];

  connect() {
    this.fonts = this.getFonts();
    Array.from(this.element.querySelectorAll('select')).map((el) =>
      this.setUpChoices(el)
    );
  }

  setUpChoices(element) {
    const choices = new Choices(element, {
      renderChoiceLimit: 5,
      choices: this.fonts,
      maxItemCount: 1,
      shouldSort: false,
      searchPlaceholderValue: 'Search Google Fonts',
      position: 'bottom',
      callbackOnCreateTemplates: this.customTemplate,
    });

    choices.setChoiceByValue(parseInt(element.dataset.selected));
  }

  getFonts() {
    const rawFonts = JSON.parse(this.fontsTarget.dataset.fonts);
    const parsedFonts = rawFonts.map((font) => {
      return { label: font[1], value: font[0] };
    });

    return [{ label: "Choose a Google font", value: "" }, ...parsedFonts]
  }

  customTemplate(template) {
    return {
      item: (classNames, data) => {
        const isPlaceholder = data.value == "";
        let stylesheet_part = `<style>
          @import url('https://fonts.googleapis.com/css2?family=${
            data.label
          }');
        </style>`
        let style_attribute_part = `style="font-family: ${data.label}"`

        if (isPlaceholder) stylesheet_part = "";
        if (isPlaceholder) style_attribute_part = "";

        return template(`
          <div>
            ${stylesheet_part}
            <div
              ${style_attribute_part}
              class="${classNames.item}
              ${
                data.highlighted
                  ? classNames.highlightedState
                  : classNames.itemSelectable
              }
              ${data.placeholder ? classNames.placeholder : ''}"
              data-item
              data-id="${data.id}"
              data-value="${data.value}"
              ${data.active ? 'aria-selected="true"' : ''}
              ${data.disabled ? 'aria-disabled="true"' : ''}
            >
              ${data.label}
            </div>
          </div>
        `);
      },
      choice: (classNames, data) => {
        const isPlaceholder = data.value == "";
        let stylesheet_part = `<style>
          @import url('https://fonts.googleapis.com/css2?family=${
            data.label
          }');
        </style>`
        let style_attribute_part = `style="font-family: ${data.label}"`

        if (isPlaceholder) stylesheet_part = "";
        if (isPlaceholder) style_attribute_part = "";

        return template(`
          <div>
            ${stylesheet_part}
            <div
              ${style_attribute_part}
              class="${classNames.item} ${classNames.itemChoice}
              ${
                data.disabled
                  ? classNames.itemDisabled
                  : classNames.itemSelectable
              }"
              data-choice
              ${
                data.disabled
                  ? 'data-choice-disabled aria-disabled="true"'
                  : 'data-choice-selectable'
              }
              data-id="${data.id}"
              data-value="${data.value}"
              ${data.groupId > 0 ? 'role="treeItem"' : 'role="option"'}
            >
              ${data.label}
            </div>
          </div>
        `);
      },
    };
  }
}

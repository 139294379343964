import { Controller } from 'stimulus';

export default class extends Controller {
  toggleVisibility(e) {
    e.preventDefault()
    let target = document.getElementById(e.target.dataset.showHideId)
    if (target.classList.contains('hide')) {
      target.classList.remove('hide')
    }
    else {
      target.classList.add('hide')
    }
  }
}

import { Controller } from "stimulus"

// How many checkboxes below me are checked?

export default class extends Controller {
  static targets = ["number"]


  connect() {
    this.checkboxes = this.element.querySelectorAll("input[type='checkbox']")
    this.updateCount = this.updateCount.bind(this)
    this.updateCount()

    this.checkboxes.forEach(box => box.addEventListener("change", this.updateCount))
  }

  disconnect() {
    this.checkboxes.forEach(box => box.removeEventListener("change", this.updateCount))
  }

  updateCount() {
    let total = 0
    this.checkboxes.forEach(el => { if (el.checked && !el.hasAttribute("readonly")) total += 1 })
    this.numberTarget.textContent = total
  }
}
import { Controller } from "stimulus";

// click a toggle button, and toggle the visibility of the controllers
// children that match the selector

export default class extends Controller {
  toggle(event) {
    const selector = this.element.dataset.showHideSelector
    const state = this.element.dataset.showHideState
    const showText = this.element.dataset.showHideShowText
    const hideText = this.element.dataset.showHideHideText

    if (state == "show") {
      this.element.dataset.showHideState = "hide"
      event.target.textContent = hideText
    } else {
      this.element.dataset.showHideState = "show"
      event.target.textContent = showText
    }

    this
      .element
      .querySelectorAll(selector)
      .forEach(element => element.classList.toggle("invisible"))
  }
}

// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import AccordionController from 'stimulus-accordion';
import Flatpickr from 'stimulus-flatpickr';

const application = Application.start();
const context = require.context('javascripts/controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));
application.register('accordion', AccordionController);

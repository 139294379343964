import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'checkboxes'];

  connect() {
    this.showHide()
  }

  showHide() {
    if (this.selectTarget.value == "") {
      this.checkboxesTarget.classList.remove('hide')
    }
    else {
      this.checkboxesTarget.classList.add('hide')
    }
  }
}

import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';
import Swal from 'sweetalert2';
import Croppie from 'croppie';

export default class extends Controller {

  static targets = [
    'files', 'template', 'file', 'pending', 'gallery', 'processed', // gallery mode
    'photos', 'photo', 'intro', 'edit', 'croppie', 'blob', 'upload', 'skip' // editor mode
  ]

  connect() {
    this.image_url = false
    this.uploading = false
    this.current_photo = -1

    if (!this.hasTemplateTarget) {
      // editor mode
      this.disable_upload()
      this.init_croppie()
      this.update_skip()
      setTimeout((() => this.first_step()), 500)

    } else {
      // gallery mode, add hook
      this.element['photoGallery'] = this
      this.upload_mode = this.data.get('mode')
      this.photo_limit = Number(this.data.get('limit'))
      this.update_uploader()
    }
  }

  disconnect() {
    if (this.croppie) {
      this.croppie.destroy()
    }
  }

  update_skip() {
    if (this.photosTarget.children.length == 1 && this.hasSkipTarget) {
      this.skipTarget.classList.add('hide')
    }
  }

  update_uploader() {
    if (this.limit_reached()) {
      this.element.classList.add('full')
    } else {
      this.element.classList.remove('full')
    }
  }

  first_step() {
    if (this.hasIntroTarget) {
      this.introTarget.classList.remove('hide')
    } else if (this.hasEditTarget) {
      this.editTarget.classList.remove('hide')
    } else {
      this.next()
    }
  }

  init_croppie(width=290, height=290) {
    const options = {
      enableExif: true,
      enableOrientation: true,
      enableResize: false,
      viewport: {
        width: width,
        height: height
      },
      boundary: {
        width: '100%',
        height: 300
      }
    }

    if (this.data.get('resize')) {
      options.enableResize = true
    }

    if (this.data.get('viewport')) {
      options.viewport.type = this.data.get('viewport')
    }

    this.croppie = new Croppie(this.croppieTarget, options)
  }

  resize() {
    if (!this.croppie) { return }

    this.croppie.bind({ url: this.image_url, orientation: 1 }).then(() => {
      this.croppie.setZoom(0)
    })
  }

  rotate() {
    if (!this.croppie) { return }

    this.croppie.rotate(90)
  }

  disable_upload() {
    this.uploadTarget.setAttribute('disabled', 'disabled')
  }

  begin_upload() {
    this.disable_upload()
    this.uploadTarget.classList.add('in-progress')
  }

  enable_upload() {
    this.uploadTarget.classList.remove('in-progress')
    this.uploadTarget.removeAttribute('disabled')
  }

  edit() {
    if (this.hasIntroTarget) {
      this.introTarget.classList.add('hide')
      this.editTarget.classList.remove('hide')
      this.next()
    }
  }

  next() {
    this.current_photo += 1

    if (this.current_photo >= this.photoTargets.length) {
      // loop back to first
      this.current_photo = 0
    }

    if (this.gallery().limit_reached()) {
      Swal.close()
      return
    }

    const photo = this.get_photo()

    if (photo) {
      this.croppie.bind({ url: photo.src }).then(() => {
        this.image_url = photo.src
        Swal.resetValidationMessage()
        this.enable_upload()
        this.croppie.setZoom(0)

        if (this.data.get('viewport') != 'circle') {
          this.croppie.destroy()

          const ratio = photo.height / 300
          const new_width = photo.width / ratio

          this.init_croppie(new_width, 300)
          this.resize()
        }

      })
    } else {
      this.all_done()
    }
  }

  done() {
    this.get_photo().remove()
    this.update_skip()
    this.next()
  }

  all_done() {
    Swal.close()
  }

  limit_reached() {
    return this.photo_limit > 0 && this.processedTargets.length >= this.photo_limit
  }

  get_photo() {
    return this.photoTargets[this.current_photo]
  }

  process() {
    this.files_array = []

    if (this.filesTarget.files) {
      let promises = []

      this.filesTarget.files.forEach((file) => {
        let file_promise = new Promise(resolve => {
          const reader = new FileReader()
          reader.onload = () => { resolve({ name: file.name, contents: reader.result }) }
          reader.readAsDataURL(file)
        })

        promises.push(file_promise)
      })

      const editor = this.templateTarget.content.firstElementChild.cloneNode(true)

      Promise.all(promises).then(files_content => {
        const photos = editor.querySelector('[data-target="photo-editor.photos"]')

        if (photos) {
          files_content.forEach((file_data) => {
            const photo_element = this.fileTarget.content.firstElementChild.cloneNode(true)
            const image = photo_element.querySelector('img')
            image.src = file_data.contents
            image.dataset.name = file_data.name

            photos.appendChild(photo_element)
          })
        }

        // empty file field
        this.filesTarget.value = null

        // load SWAL
        Swal.fire({
          title: "<h1>Prepare your photos<h1>",
          html: editor.outerHTML,
          showCancelButton: false,
          showConfirmButton: false,
          buttonsStyling: false,
          customClass: {
            container: 'overlay-background',
            popup: 'overlay-content',
            confirmButton: 'button',
            cancelButton: 'button outlined red'
          }
        })
      })
    }
  }

  cancel() {
    Swal.close()
  }

  delete() {
    this.deleteTarget.setAttribute('disabled', 'disabled')
    this.deleteTarget.classList.add('in-progress')
  }

  upload(e) {
    if (this.uploading) {
      return
    }
    this.uploading = true

    Swal.resetValidationMessage()

    this.croppie.result({
      type: 'blob',
      format: 'jpeg',
      quality: 0.9,
      size: this.get_max_size(1000),
      circle: false
    }).then((blob) => {
      blob.name = this.get_photo().dataset.name

      this.begin_upload()
      const uploader = new DirectUpload(blob, this.element.dataset.directUploadUrl);
      uploader.create((error, uploaded_blob) => {
        if (error) {
          Swal.showValidationMessage("Sorry, an error occurred when saving your photo. Please try again")
          this.enable_upload()
          this.uploading = false

        } else if (this.gallery().upload_mode == 'create') {
          this.add_pending(uploaded_blob.signed_id)

        } else if (this.gallery().upload_mode == 'replace') {
          this.clear_gallery()
          this.add_pending(uploaded_blob.signed_id)

        } else {
          this.blobTarget.value = uploaded_blob.signed_id
          Rails.fire(this.element, 'submit')
        }
      })
    })
  }

  get_max_size(limit) {
    const points = this.croppie.get().points

    const width = Number(points[2]) - Number(points[0])
    const height = Number(points[3]) - Number(points[1])

    const max_size = {}

    if (width > height) {
      max_size.width = Math.min(limit, width)
    } else {
      max_size.height = Math.min(limit, height)
    }

    return max_size
  }

  clear_gallery() {
    this.gallery().galleryTarget.children.forEach((element) => {
      // keep the no-images message, remove the rest
      if (!element.classList.contains('no-images')) {
        element.remove()
      }
    })

    this.update_uploader()
  }

  add_pending(signed_id) {
    const photos = this.gallery().galleryTarget
    const pending_photo = this.gallery().pendingTarget.content.firstElementChild.cloneNode(true)

    pending_photo.querySelector('input').value = signed_id

    const image = pending_photo.querySelector('img')

    this.croppie.result({
      type: 'base64',
      format: 'jpeg',
      quality: 0.9,
      size: this.get_max_size(300),
      circle: false
    }).then((base64) => {
      image.src = base64
      photos.appendChild(pending_photo)
      this.gallery().update_uploader()

      this.uploading = false
      this.done()
    })
  }

  uploaded(e) {
    const [data, status, xhr] = e.detail;

    let new_image = data.querySelector('.cell');
    this.gallery().galleryTarget.appendChild(new_image)
    this.gallery().update_uploader()
    this.uploading = false
    this.done()
  }

  failed() {
    Swal.showValidationMessage("Sorry, an error occurred when saving your photo. Please try again")
    this.uploading = false
  }

  deleted(e) {
    e.target.closest('[data-target="photo-editor.processed"]').remove()
    this.update_uploader()
  }

  gallery() {
    return document.getElementById(this.data.get('gallery')).photoGallery
  }
}

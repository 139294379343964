import { Controller } from "stimulus"

// basically like the toggle controller, but for when you are using a boolean input
// to control what is shown / hidden
export default class extends Controller {
  static targets = ["flip", "flop", "input"]

  connect() {
    this.toggle()
  }

  toggle(event) {
    const yes = this.inputTargets.find(el => el.value == "true")
    const no = this.inputTargets.find(el => el.value == "false")

    if(yes.checked) {
      this.flopTargets.forEach(element => element.style.setProperty("display", "none"))
      this.flipTargets.forEach(element => element.style.removeProperty("display"))
    } else if (no.checked) {
      this.flipTargets.forEach(element => element.style.setProperty("display", "none"))
      this.flopTargets.forEach(element => element.style.removeProperty("display"))
    } else {
      this.flipTargets.forEach(element => element.style.setProperty("display", "none"))
      this.flopTargets.forEach(element => element.style.setProperty("display", "none"))
    }
  }

}

import { Controller } from 'stimulus';
import { DirectUpload } from '@rails/activestorage';
import Rails from '@rails/ujs';
import Swal from 'sweetalert2';
import Croppie from 'croppie';

export default class extends Controller {

  static targets = ['file', 'label', 'croppie', 'modal', 'upload', 'blob', 'preview', 'form', 'selectPhotoButton', 'placeholder', 'removePhotoLink'];

  connect() {
    this.image_url = false
    this.uploading = false
    this.updateBlobInput()
  }

  disconnect() {
    this.destroy_croppie();
  }

  open_file_browser() {
    this.fileTarget.disabled = false;
    this.blobTarget.disabled = false;
    this.labelTarget.click();
  }

  updateBlobInput() {
    this.blobTarget.disabled = (this.blobTarget.value == "")
  }

  process() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      let reader = new FileReader();
      let ratio = this.element.dataset.ratio || 1

      reader.onload = (e) => {
        let img = new Image();
        img.src = e.target.result;
        setTimeout(function (){
          if (!self.croppie) {
            self.croppie = new Croppie(self.croppieTarget, {
              enableExif: true,
              viewport: {
                width: 300,
                height: 300 * ratio,
                type: 'square'
              },
              boundary: {
                width: '100%',
                height: 300
              }
            })
          }

          // bind croppie
          self.modalTarget.classList.remove('hide')
          self.croppie.bind({ url: e.target.result }).then(() => {
            self.croppie.setZoom(0)
            self.image_url = e.target.result
          });
        }, 50, self=this, img);
      }

      reader.readAsDataURL(this.fileTarget.files[0]);
    }

    this.removePhotoLinkTarget.classList.remove('hide')
  }

  upload(e) {
    if (this.uploading) {
      return
    }
    this.uploading = true

    this.croppie.result({
      type: 'blob',
      format: 'jpeg',
      size: 'original' // TODO figure out what to do here
    }).then((blob) => {
      blob.name = this.fileTarget.files[0].name

      // this.begin_upload()

      const uploader = new DirectUpload(blob, this.fileTarget.dataset.directUploadUrl);
      uploader.create((error, uploaded_blob) => {
        if (error) {
          Swal.showValidationMessage("Sorry, an error occurred when uploading. Please try again")
          this.enable_upload()

        } else {
          this.blobTarget.value = uploaded_blob.signed_id
          this.updateBlobInput()
          const uri = URL.createObjectURL(blob)
          this.previewTarget.src = uri
          this.unhidePreviewImage()
          this.modalTarget.classList.add('hide')
          this.placeholderTarget.classList.add('hide')
          this.selectPhotoButtonTarget.classList.add('hide')
          this.destroy_croppie();
        }

        this.uploading = false
      })
    })
  }

  cancel(e) {
    this.modalTarget.classList.add('hide');
    this.destroy_croppie();
    this.updateBlobInput()
  }

  removePhoto() {
    this.hidePreviewImage()
    this.blobTarget.value = ''
    this.blobTarget.disabled = true
    this.fileTarget.value = ''
    this.fileTarget.disabled = true
    this.removePhotoLinkTarget.classList.add('hide')
    this.uploading = false
  }

  destroy_croppie() {
    if (this.croppie) {
      this.croppie.destroy()
    }
  }

  unhidePreviewImage() {
    this.previewTarget.classList.remove('hide');
  }

  hidePreviewImage() {
    this.previewTarget.classList.add('hide');
  }

}

import { Controller } from "stimulus"

// controller for monitoring the page for form submissions and
// refreshing the website preview when they happen.

export default class extends Controller {
  static targets = ["iframe"]

  connect() {
    this.refresh = this.refresh.bind(this)
    this.setAnchor = this.setAnchor.bind(this)

    this.element.addEventListener("preview:refresh", this.refresh)
    this.element.addEventListener("preview:setAnchor", this.setAnchor)
  }

  disconnect() {
    this.element.removeEventListener("preview:refresh", this.refresh)
    this.element.removeEventListener("preview:setAnchor", this.setAnchor)
  }

  // set the current tab of the wedding preview. this used when we need to
  // redirect people to a different tab upon doing something in the editor
  setAnchor(event) {
    this
      .iframeTarget
      .contentWindow
      .location
      .hash = event.detail.anchor
  }

  refresh(event) {
    this
      .iframeTarget
      .contentWindow
      .location
      .reload()
  }
}

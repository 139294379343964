import { Controller } from 'stimulus';
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  static targets = ['counter'];

  connect() {
    this.value = parseFloat(this.element.dataset.value);

    this.prefix = this.element.dataset.prefix || '';
    this.hasBeenSeen = false;

    const targetSeconds = this.element.dataset.seconds || 2.5;
    const number_of_increments = Math.floor(targetSeconds / (50/1000))

    if (this.value == 0) {
      this.setNewValue(this.prefix, 0, 0)
      return;
    }

    this.interval = 50 //ms
    this.increment = this.value / number_of_increments

    useIntersection(this)
  }

  appear(entry) {
    if (this.hasBeenSeen == true) {
      return;
    }

    this.hasBeenSeen = true;

    var currentValue = 0
    var counter = 0
    while(currentValue <= Math.floor(this.value)) {
      this.setNewValue(this.prefix, Math.floor(currentValue), counter);
      currentValue+=this.increment;
      counter++
    }
    if (currentValue != this.value) {
      this.setNewValue(this.prefix, this.value, counter)
    }
  }

  setNewValue(prefix, newVal, counter) {
    setTimeout(() => {
      let newContent = `${prefix}${newVal.toLocaleString()}`;
      this.counterTarget.innerHTML = newContent;
    }, this.interval * counter);
  }

}

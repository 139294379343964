import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = [];

  connect(e){

    Swal.fire({
      title: '<h1>Get started on your guest list<h1>',
      html: "<p>Before creating Save The Dates we need to add some guests.</p><p>Add a new guest using their name and email address. You can then add their partner, family members or Plus One to create a group if needed.</p>",
      showCancelButton: false,
      confirmButtonText: 'Get started',
      buttonsStyling: false,
      customClass: {
        container: 'overlay-background',
        popup: 'overlay-content',
        confirmButton: 'button'
      }
    })

  }
}



import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['attending', 'notAttending', 'showForYes', 'controls', 'eventDetails', 'eventDetailsToggle'];

  connect() {
    if (this.hasAttendingTarget && this.hasNotAttendingTarget && this.hasShowForYesTarget && this.hasShowForNoTarget) {
      this.showOrHideBlocks();
    }
  }

  changeAttending() {
    this.showOrHideBlocks();

    let originallyAttending = this.attendingTarget.dataset.originalValue === 'true';
    let nowAttending = this.attendingTarget.checked;

    this.showForYesTarget.querySelectorAll('input[type="checkbox"]').forEach(el => {
      if (nowAttending) {
        if (originallyAttending) {
          el.checked = el.dataset.originalValue === 'true';
        } else {
          el.checked = true;
        }
      } else {
        el.checked = false;
      }
    });
  }

  showOrHideBlocks() {
    if (this.attendingTarget.checked) {
      this.showForYesTarget.classList.remove('hide');
      if(this.hasControlsTarget){
        this.controlsTarget.classList.remove('hide');
      }
    } else if (this.notAttendingTarget.checked) {
      this.showForYesTarget.classList.add('hide');
      if(this.hasControlsTarget){
        this.controlsTarget.classList.remove('hide');
      }
    }
  }

  showEventDetails(e) {
    e.preventDefault();
    this.eventDetailsTarget.classList.remove('hide');
    this.eventDetailsToggleTarget.classList.add('hide');
  }
}

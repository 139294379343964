import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['addImageLink']

  connect() {
    this.refreshAddImageLink();
  }

  numberOfImageFields() {
    return document.querySelectorAll('.gift-fund-image-fields').length
  }

  imageMax() {
    return parseInt(this.element.dataset.maxImages)
  }

  refreshAddImageLink() {
    const imageMax = this.imageMax();
    const numberOfImageFields = this.numberOfImageFields();

    if (numberOfImageFields >= imageMax) {
      this.addImageLinkTarget.classList.add('hide')
    }
    else {
      this.addImageLinkTarget.classList.remove('hide')
    }
  }
}

// start with a timestamp, and maintain an 'X time ago' message

import { Controller } from "stimulus";
import { formatDistanceToNow } from "date-fns";

export default class extends Controller {
  connect() {
    this.timestamp = new Date(this.element.dataset.timestamp)
    this.update = () => { this.element.textContent = formatDistanceToNow(this.timestamp) }

    this.update()
    this.interval = setInterval(this.update, 10 * 1000)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}